import React from "react";
import Header from "components/Header";
import JoinOurCommunity from "components/JoinOurCommunity";
import Footer from "components/Footer";
import SectionOne from "../components/OTC/SectionOne";
// import SectionTwo from "../components/OTC/SectionTwo";
import SectionThree from "components/OTC/SectionThree";
import SectionFour from "components/OTC/SectionFour";
import SectionFive from "components/OTC/SectionFive";
import FAQs from "components/OTC/FAQs";

const OTC = () => {
  return (
    <>
      <Header />
      <SectionOne />
      {/* <SectionTwo /> */}
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <FAQs />
      <JoinOurCommunity />
      <Footer />
    </>
  );
};

export default OTC;
