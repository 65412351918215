import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Content from "components/PrivacyPolicy/Content";
import SectionOne from "components/PrivacyPolicy/SectionOne";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <SectionOne />
      <Content />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
