import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

const ReceiveInInput = ({
  receiveInCurrency,
  supportedCurrencies,
  handleSwapOrderRate,
  setMinAmountErrorMessage,
  setMaxAmountErrorMessage,
  receiveInValue,
  maxAmount,
  setPayInValue,
  setReceiveInValue,
  setReceiveInNetwork,
  receiveInCurrencyImg,
  setReceiveInCurrencyImg,
  setReceiveInCurrency,
  payInCurrency,
  payInNetwork,
  receiveInNetwork,
  setMaxAmount,
  setMinAmount,
  minAmount,
}) => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <div className="relative mt-1 mb-8">
        {" "}
        <div className="flex flex-wrap items-stretch w-full mb-4 relative cursor-pointer">
          <input
            type="text"
            className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-2 border-[#B8B8B8] border-r-0  outline-none border-grey-light px-4 py-[.6rem] relative rounded-r-none text-[#7C7C7C]
            rounded-[32px] placeholder:text-sm lg:text-base text-xs placeholder:text-[#7C7C7C]"
            placeholder="Enter amount"
            value={receiveInValue}
            onChange={(e) => {
              handleSwapOrderRate(
                "receiveIn",
                e.target.value,
                payInCurrency,
                payInNetwork,
                receiveInNetwork,
                setMaxAmount,
                setMinAmount,
                receiveInCurrency,
                setPayInValue,
                setMinAmountErrorMessage,
                setMaxAmountErrorMessage,
                setReceiveInValue
              );
              if (e.target.value < minAmount) {
                setMinAmountErrorMessage(true);
              } else if (e.target.value > maxAmount) {
                setMaxAmountErrorMessage(true);
              } else {
                setMinAmountErrorMessage(false);
                setMaxAmountErrorMessage(false);
              }
            }}
          />
          <div
            className="flex pr-3 items-center -mr-px border-l-0 border-2 border-[#B8B8B8] rounded-[32px]  rounded-l-none "
            onClick={() => setToggle(!toggle)}
          >
            {" "}
            <img
              src={receiveInCurrencyImg}
              alt="currencyImage"
              height={14}
              width={14}
            />
            <span className="flex leading-normal pl-2 pr-3 whitespace-no-wrap text-[#525252] text-sm">
              {receiveInCurrency}
            </span>
            {!toggle ? (
              <ChevronDownIcon
                color="#0747A6"
                className="font-bold"
                height={18}
              />
            ) : (
              <ChevronUpIcon
                color="#0747A6"
                className="font-bold"
                height={18}
              />
            )}
          </div>
        </div>
        {toggle && (
          <div className="absolute right-0 top-12 bg-white z-10 py-3 shadow">
            {supportedCurrencies?.map((item, i) => (
              <div
                className="py-2 px-3 text-xs flex items-center cursor-pointer hover:bg-[#EBEBEB]"
                onClick={() => {
                  setPayInValue("");
                  setToggle(false);
                  setReceiveInValue("");
                  setReceiveInCurrency(item?.currencySymbol);
                  setReceiveInCurrencyImg(item?.symbolUrl);
                  setReceiveInNetwork(item?.network);
                }}
                key={i}
              >
                {item?.symbolUrl && (
                  <>
                    <img
                      src={item?.symbolUrl}
                      alt="currencyImage"
                      height={14}
                      width={14}
                    />
                    &nbsp;&nbsp;
                  </>
                )}{" "}
                <strong>{item?.currencySymbol}</strong>
                <>
                  {item?.network !== "LOCAL" && (
                    <>
                      &nbsp;&nbsp;
                      <strong>({item?.network})</strong>
                    </>
                  )}
                </>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {item?.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ReceiveInInput;
