import React from "react";
import { partners } from "utils/fx";
import Bounce from "react-reveal/Bounce";
import Marquee from "react-fast-marquee";

const TrustedPartners = () => {
  return (
    <div className=" mx-auto   px-5 mb-20 lg:mb-40 font-mena text-center  "  >
      {" "}
      <h4 className=" text-3xl mb-3">Trusted partners worldwide</h4>
      <div className="  flex justify-center">
        <p className="text-[#7C7C7C] lg:w-[540px] lg:mb-16 lg:block hidden ">
          We've partnered with several businesses and individuals across the
          globe.
        </p>{" "}
      </div>
      <Bounce right cascade>
        <div className="flex flex-wrap " id="features">
          <Marquee speed={80}  gradientWidth={0}>
            {partners.map(({ link, image }, i) => (
              <div className="lg:w-1/6 w-1/2  p-2" key={i}>
                <a href={link} target="_blank" rel="noreferrer">
                  <img src={image} alt="Wicrypt" className="hover:opacity-40" />
                </a>
              </div>
            ))}{" "}
          </Marquee>
        </div>
      </Bounce>
    </div>
  );
};

export default TrustedPartners;
