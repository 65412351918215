import React from "react";
import { aboutOTC } from "utils/fx";
import Fade from "react-reveal/Fade";

const SectionFour = () => {
  return (
    <div className="container mx-auto lg:px-[8.3rem] px-5 lg:mb-40 mb-20 mt-20 lg:mt-40 font-mena  ">
      <h4 className="lg:text-7xl text-5xl mb-2  ">So, how does it work?</h4>
      <p className="text-[#525252] mb-5 lg:mb-20">
        It takes 4 simple steps to start transacting on our OTC desk
      </p>

      <Fade right cascade>
        <div className="grid lg:grid-cols-4 grid-cols-1 gap-10">
          {aboutOTC.map(({ title, description, image, linkTitle, link }, i) => (
            <div className="w-full  " key={i}>
              <img src={image} alt="icon" className="lg:mb-5 mb-0" />
              <h6 className="text-lg  text-[#080A0C]  lg:my-3 my-2">{title}</h6>
              <p
                className={`text-sm  text-[#525252] ${
                  description.includes("complete") && "lg:mt-10"
                }`}
              >
                {description} <a className="underline text-[#0747A6]" href={link}>{linkTitle}</a>.
              </p>
            </div>
          ))}
        </div>
      </Fade>
    </div>
  );
};

export default SectionFour;
