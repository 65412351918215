import {
  useGetOrderRateBoundsMutation,
  useGetOrderRateMutation,
} from "services/order.service";
import { toast } from "react-toastify";

const useOrder = () => {
  const [getOrderRateBounds] = useGetOrderRateBoundsMutation();
  const [getOrderRate] = useGetOrderRateMutation();

  const getBestRate = async (
    cryptoCurrency,
    fiatCurrency,
    cryptoNetwork,
    fiatNetwork,
    type,
    payInCurrency,
    payInCurrencyNetwork,
    receiveInCurrency,
    receiveInNetwork,
    setBestRate,
    setStartingRate,
    setRateError
  ) => {
    let _payload = {
      payInCurrencyCode: payInCurrency,
      payInCurrencyNetwork: payInCurrencyNetwork,
      receiveInCurrencyCode: receiveInCurrency,
      receiveInCurrencyNetwork: receiveInNetwork,
      orderAmount: "",
    };

    let payload = {
      payInCurrencyCode: cryptoCurrency,
      payInCurrencyNetwork: cryptoNetwork,
      receiveInCurrencyCode: fiatCurrency,
      receiveInCurrencyNetwork: fiatNetwork,
      orderAmount: "",
    };

    let payload_ = {
      payInCurrencyCode: fiatCurrency,
      payInCurrencyNetwork: fiatNetwork,
      receiveInCurrencyCode: cryptoCurrency,
      receiveInCurrencyNetwork: cryptoNetwork,
      orderAmount: "",
    };
    let res;
    try {
      if (type === "sell") {
        res = await getOrderRateBounds(payload).unwrap();
      }

      if (type === "buy") {
        res = await getOrderRateBounds(payload_).unwrap();
      }

      if (type === "swap") {
        res = await getOrderRateBounds(_payload).unwrap();
      }

      if (res?.data) {
        setBestRate(res?.data?.bestRate?.exchangeRate);
        setStartingRate(res?.data?.worstRate?.exchangeRate);
        setRateError();
      } else {
        setBestRate();
        setStartingRate();
        setRateError(res?.Message);
      }
    } catch (error) {
      setBestRate();
      setStartingRate();
      setRateError(error?.data?.Message);
    }
  };

  const handleOrderRate = async (
    type,
    value,
    cryptoCurrency,
    cryptoNetwork,
    fiatCurrency,
    fiatNetwork,
    setFiatValue,
    setMaxAmount,
    setMinAmount,
    setMaxAmountErrorMessage,
    setMinAmountErrorMessage,
    setCryptoValue
  ) => {
    // Accepts only integer and decimal
    let formattedValue = value.replace(/[^0-9.]/gi, "");

    if (type === "FIAT") {
      setFiatValue(formattedValue);
      try {
        let cryptoPayload = {
          payInCurrencyCode: cryptoCurrency,
          payInCurrencyNetwork: cryptoNetwork,
          receiveInCurrencyCode: fiatCurrency,
          receiveInCurrencyNetwork: fiatNetwork,
          orderAmount: formattedValue,
        };

        let response = await getOrderRate(cryptoPayload).unwrap();

        setMaxAmount(response?.data?.maximumAmount);
        setMinAmount(response?.data?.minimumAmount);

        if (
          formattedValue / response?.data?.exchangeRate <
          response?.data?.minimumAmount
        ) {
          setMinAmountErrorMessage(true);
        } else if (
          formattedValue / response?.data?.exchangeRate >
          response?.data?.maximumAmount
        ) {
          setMaxAmountErrorMessage(true);
        } else {
          setMinAmountErrorMessage(false);
          setMaxAmountErrorMessage(false);
        }
        formattedValue !== ""
          ? setCryptoValue(
              parseInt(formattedValue) / response?.data?.exchangeRate
            )
          : setCryptoValue("");
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || "Oops, Something went wrong");
      }
    } else {
      setCryptoValue(formattedValue);
      try {
        let fiatPayload = {
          payInCurrencyCode: fiatCurrency,
          payInCurrencyNetwork: fiatNetwork,
          receiveInCurrencyCode: cryptoCurrency,
          receiveInCurrencyNetwork: cryptoNetwork,
          orderAmount: formattedValue,
        };

        let response = await getOrderRate(fiatPayload).unwrap();

        setMaxAmount(response?.data?.maximumAmount);
        setMinAmount(response?.data?.minimumAmount);

        formattedValue !== ""
          ? setFiatValue(
              response?.data?.exchangeRate * parseInt(formattedValue)
            )
          : setFiatValue("");
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || "Oops, Something went wrong");
      }
    }
  };

  const handleSwapOrderRate = async (
    type,
    value,
    payInCurrency,
    payInNetwork,
    receiveInNetwork,
    setMaxAmount,
    setMinAmount,
    receiveInCurrency,
    setPayInValue,
    setMinAmountErrorMessage,
    setMaxAmountErrorMessage,
    setReceiveInValue
  ) => {
    // Accepts only integer and decimal
    let formattedValue = value.replace(/[^0-9.]/gi, "");

    if (type === "PayIn") {
      let payload = {
        payInCurrencyCode: payInCurrency,
        payInCurrencyNetwork: payInNetwork,
        receiveInCurrencyCode: receiveInCurrency,
        receiveInCurrencyNetwork: receiveInNetwork,
        orderAmount: formattedValue,
      };

      setPayInValue(formattedValue);
      try {
        let response = await getOrderRate(payload).unwrap();

        setMaxAmount(response?.data?.maximumAmount);
        setMinAmount(response?.data?.minimumAmount);

        if (parseInt(formattedValue) * response?.data?.exchangeRate < 1) {
          setMinAmountErrorMessage(true);
        } else {
          setMinAmountErrorMessage(false);
        }

        if (parseInt(formattedValue) < response?.data?.minimumAmount) {
          setMinAmountErrorMessage(true);
        } else if (parseInt(formattedValue) > response?.data?.maximumAmount) {
          setMaxAmountErrorMessage(true);
        } else {
          setMinAmountErrorMessage(false);
          setMaxAmountErrorMessage(false);
        }

        formattedValue !== ""
          ? setReceiveInValue(
              parseInt(formattedValue) * response?.data?.exchangeRate
            )
          : setReceiveInValue("");
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || "Oops, Something went wrong");
      }
    } else {
      let payload = {
        payInCurrencyCode: receiveInCurrency,
        payInCurrencyNetwork: receiveInNetwork,
        receiveInCurrencyCode: payInCurrency,
        receiveInCurrencyNetwork: payInNetwork,
        orderAmount: formattedValue,
      };

      setReceiveInValue(formattedValue);
      try {
        let response = await getOrderRate(payload).unwrap();

        setMaxAmount(response?.data?.maximumAmount);
        setMinAmount(response?.data?.minimumAmount);

        if (parseInt(formattedValue) * response?.data?.exchangeRate < 1) {
          setMinAmountErrorMessage(true);
        } else {
          setMinAmountErrorMessage(false);
        }

        if (parseInt(formattedValue) < response?.data?.minimumAmount) {
          setMinAmountErrorMessage(true);
        } else if (parseInt(formattedValue) > response?.data?.maximumAmount) {
          setMaxAmountErrorMessage(true);
        } else {
          setMinAmountErrorMessage(false);
          setMaxAmountErrorMessage(false);
        }

        formattedValue !== ""
          ? setPayInValue(
              response?.data?.exchangeRate * parseInt(formattedValue)
            )
          : setPayInValue("");
      } catch ({ response }) {
        const { Message } = response?.data;
        toast.error(Message || "Oops, Something went wrong");
      }
    }
  };

  return { getBestRate, handleOrderRate, handleSwapOrderRate };
};

export default useOrder;
