import React from "react";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

const SectionThree = () => {
  return (
    <>
      <div className="container mx-auto mt-32 lg:mt-20 lg:mt-32 lg:px-[8.3rem] px-5 font-mena lg:flex lg:gap-14">
        <Fade left cascade>
          <div className="lg:w-1/4 lg:mb-0 mb-10">
            <h4 className="lg:text-[32px] 2xl:text-[34px] text-5xl ">
              Why Trade OTC with XendBridge ?
            </h4>
          </div>{" "}
        </Fade>
        <Zoom top cascade>
          <div className="lg:w-3/4">
            <div className="lg:flex ">
              <div className="lg:w-1/2 lg:mb-10 lg:pr-10 mb-8 ">
                <img src="images/buy-sell.svg" alt="icon" />
                <h6 className="text-xl mt-3 lg:mb-2">Trade large volumes</h6>
                <p className="text-lg text-[#525252]">
                  Trade large volumes of crypto, as high as $1,000,000 and
                  above.{" "}
                </p>
              </div>
              <div className="lg:w-1/2 lg:mb-10 lg:px-10 mb-8">
                <img src="images/payment.svg" alt="icon" />
                <h6 className="text-xl mt-3 lg:mb-2">Instant settlements</h6>
                <p className="text-lg text-[#525252]">
                  Enjoy quicker settlement times on your crypto deals.
                  Settlements occur almost immediately after the trade.{" "}
                </p>
              </div>
            </div>
            <div className="lg:flex">
              <div className="lg:w-1/2 lg:mb-10 lg:pr-10 mb-8">
                <img src="images/security-check.svg" alt="icon" />
                <h6 className="text-xl mt-3 lg:mb-2">
                  Secure and reliable transactions
                </h6>
                <p className="text-lg text-[#525252]">
                  Trade on a secured and reliable platform. Our liquidity
                  providers undergo a thorough verification process.{" "}
                </p>
              </div>
              <div className="lg:w-1/2 lg:mb-10 lg:px-10 mb-8">
                <img src="images/24-hours.svg" alt="icon" />
                <h6 className="text-xl mt-3 lg:mb-2">24/7 trading access</h6>
                <p className="text-lg text-[#525252]">
                  Our team is always onboard to manage the execution of your
                  trade at any time of the day, regardless of your location or
                  time zone{" "}
                </p>
              </div>{" "}
            </div>
          </div>
        </Zoom>
      </div>
      <div className="lg:flex">
        {" "}
        <div className="lg:w-[43%]"></div>
        <div className="text-center lg:text-left lg:w-[68%] mt-8 mb-10">
          {" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeDQ5VB3F6aoReV3DtXDvRnpePQHulFUHWTpqN9XsmMKt37Ow/viewform"
            className="mr-7 px-8 bg-[#0747A6] text-[#fff] py-3 rounded-[42px]  hover:opacity-90"
            target={"_blank"}
            rel="noreferrer"
          >
            Request a quote{" "}
          </a>{" "}
        </div>{" "}
      </div>
    </>
  );
};

export default SectionThree;
