import React from "react";
import Slide from "react-reveal/Slide";

const SectionFour = () => {
  return (
    <div className="bg-hero-mask bg-hero-mask-sm   bg-cover bg-center bg-no-repeat  bg-[#F2F8FF] lg:mb-5 lg:p-20 font-mena p-10 h-[1000px] lg:h-auto ">
      <Slide left cascade>
        <div className="container mx-auto lg:px-[8.3rem]">
          {" "}
          <h2 className="lg:text-6xl lg:w-[800px] text-5xl font-normal">
            Ready to trade your first crypto with XendBridge?
          </h2>
          <div className="mt-8 lg:flex lg:items-center lg:mt-28 lg:w-[500px]">
            <a
              href="https://lite.xendbridge.com/"
              className="mr-7 px-6 bg-[#0747A6] text-white py-3 text-center rounded-[42px]  font-space lg:w-1/2 w-full hover:opacity-90 block mb-3"
              target="_blank"
              rel="noreferrer"
            >
              Trade Crypto
            </a>{" "}
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default SectionFour;
