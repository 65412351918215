import React from 'react';
import Fade from 'react-reveal/Fade';
import animationData from '../../assets/headerart.json';
import Lottie from 'react-lottie';
import { Link, useLocation } from 'react-router-dom';

const SectionOne = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const location = useLocation();

  return (
    <div className="  relative bg-[#0747a6] font-mena">
      <div className="lg:pl-[13rem]  container  flex justify-between items-center lg:pb-4 lg:pt-20  pl-5 pb-10 pt-5 ">
        <Fade bottom>
          <div>
            <h1 className="text-white text-[22px] lg:text-[32px] mb-0 text-center">
              Terms and Conditions
            </h1>
            <p className="text-[10px] lg:text-[14px] text-white">
              Last updates: 4 months ago
            </p>
          </div>
        </Fade>
        <Fade right>
          <div className="lg:w-[320px] w-[190px]">
            <Lottie options={defaultOptions} style={{ objectFit: 'cover' }} />
          </div>
        </Fade>
      </div>
      <div className="flex justify-center lg:gap-12 gap-4 text-[14px] lg:text-[16px] text-white font-[500]">
        <Link
          className={`cursor-pointer ${
            location?.pathname === '/terms-and-conditions'
              ? 'border-b-[4px] border-[#FFFFFF]'
              : 'pb-3'
          }`}
          to={'/terms-and-conditions'}
        >
          Terms And Conditions{' '}
        </Link>
        <Link
          className={`cursor-pointer ${
            location?.pathname === '/privacy-policy'
              ? 'border-b-[4px] border-[#FFFFFF]'
              : 'pb-3'
          }`}
          to={'/privacy-policy'}
        >
          Privacy Policy{' '}
        </Link>
        {/* <p>Cookie Policy </p> */}
      </div>
    </div>
  );
};

export default SectionOne;
