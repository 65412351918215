import React from "react";
import { partners } from "utils/fx";
import Fade from "react-reveal/Fade";

const SectionSix = () => {
  return (
    <div className="container mx-auto lg:px-[8.3rem] px-5 mb-20 font-mena  ">
      <div className=" lg:flex">
        <div className="lg:w-1/2">
          <h4 className=" lg:text-5xl 2xl:text-4xl text-4xl mb-2  lg:mt-10">
            Trusted business partners{" "}
          </h4>
        </div>
        <Fade bottom cascade>
          <div className="flex flex-wrap lg:w-[450px]">
            {partners.map(({ link, image }, i) => (
              <div className=" w-1/2  my-3" key={i}>
                <a href={link} target="_blank" rel="noreferrer">
                  <img
                    src={image}
                    alt="Wicrypt"
                    className="hover:opacity-40 lg:w-[200px] w-[150px]"
                  />
                </a>
              </div>
            ))}
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default SectionSix;
