import React from "react";
import { Cryptos } from "utils/fx";
import Bounce from "react-reveal/Bounce";

const Crytocurrencies = () => {
  return (
    <div className="lg:flex  lg:gap-20 container mx-auto justify-between items-start gap-10 lg:px-[8.3rem] lg:py-20 lg:p-0  font-mena p-5 lg:mb-20 mb-20 ">
      <div className="lg:w-[2/3] w-full lg:text-5xl text-4xl font-bold lg:mb-0 mb-14">
        Cryptocurrencies we support
      </div>{" "}

      <Bounce bottom cascade>
        <div className="flex flex-wrap lg:gap-10 gap-3 lg:mt-0 mt-5">
          {Cryptos.map(({ image }, i) => (
            <img src={image} alt="icon" className="lg:w-[80px] w-[70px]" key={i} />
          ))}
        </div>
      </Bounce>
    </div>
  );
};

export default Crytocurrencies;
