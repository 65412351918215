import React from "react";
import Slide from "react-reveal/Slide";

const SectionEight = () => {
  return (
    <div className="bg-hero-mask-blue bg-hero-mask-blue-sm  font-mena bg-cover bg-center bg-no-repeat mb-20  bg-[#fff] lg:mb-10 lg:p-20 font-mena p-10 lg:h-auto ">
      <Slide left cascade>
        <div className="container mx-auto lg:px-[8.3rem]">
          <div className="lg:flex items-end mb-4">
            <h5 className="text-[#080A0C] lg:text-5xl text-3xl font-bold mr-2">
              Xend Bridge Lite
            </h5>{" "}
            <span className="text-[#0747A6] ">For Individual</span>
          </div>
          <h2 className="lg:text-3xl lg:w-[580px] font-normal text-xl">
            Buying and selling cryptocurrencies shouldn't be hard. That's why we
            have made it super easy for you.
          </h2>
   
          <div className="mt-8 lg:flex lg:items-center lg:mt-20 lg:w-[500px]">
            <a
              href="https://lite.xendbridge.com/"
              className="mr-7 px-6 bg-[#0747A6] text-white py-3 text-center rounded-[42px]  font-space lg:w-1/2 w-2/3 hover:opacity-90 block mb-3"
              target="_blank"
              rel="noreferrer"
            >
              Start Trading
            </a>{" "}
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default SectionEight;
