import React from "react";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";

const Header = ({ is404 }) => {
  return (
    <>
      <DesktopHeader is404={is404} />
      <MobileHeader is404={is404} />
    </>
  );
};

export default Header;
