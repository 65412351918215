import FinancialReportModal from 'components/Modal/FinancialReportModal';
import Business from 'pages/Business';
import Individual from 'pages/Individual';
import OTC from 'pages/OTC';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './pages/index';
import Page404 from 'pages/Page404';
import TermsAndCondition from 'pages/TermsAndCondition';

function App() {
  const [openModal, setOpenModal] = useState(false);
  let isfinancialReportViewed = JSON.parse(
    sessionStorage.getItem('financialReportViewed')
  );
  useEffect(() => {
    //enforce an  https on the client side
    if (window.location.href.substr(0, 5) !== 'https') {
      window.location.href = window.location.href.replace('http', 'https');
    }
  }, []);

  useEffect(() => {
    // Display the financial report on first load after 30secs
    if (isfinancialReportViewed === null) {
      const timeout = setTimeout(() => {
        setOpenModal(true);
        sessionStorage.setItem('financialReportViewed', true);
      }, 30000);
      return () => clearTimeout(timeout);
    }
  }, [isfinancialReportViewed]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/individual" element={<Individual />} />
          <Route path="/business" element={<Business />} />
          <Route path="/otc-desk" element={<OTC />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>

      {openModal && <FinancialReportModal setOpenModal={setOpenModal} />}
    </>
  );
}

export default App;
