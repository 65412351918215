import { api } from "./index";

export const configEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    getSupportedCurrencies: build.mutation({
      query: () => ({
        url: "/Order/SupportedCurrencies",
        method: "GET",
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetSupportedCurrenciesMutation } = configEndpoint;
