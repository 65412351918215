import React from "react";
import { about } from "utils/fx";
import Fade from "react-reveal/Fade";

const About = () => {
  return (
    <div className="container mx-auto lg:px-[8rem]  px-5 mb-20 font-mena ">
      <h4 className="text-center lg:text-4xl text-3xl mb-5 lg:mb-20 font-bold">
        Why XendBridge?
      </h4>

      <Fade right cascade>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-16">
          {about.map(({ title, description, image }, i) => (
            <div className="w-full  " key={i}>
              <img src={image} alt="icon" />
              <h6 className="lg:text-[1.35rem] text-lg  my-3">{title}</h6>
              <p className="text-[.95rem]">{description}</p>
            </div>
          ))}
        </div>
      </Fade>
    </div>
  );
};

export default About;
