import React, { useState } from "react";
import { autoSlideDuration, testimonials } from "../../utils/fx";

const Testimonial = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  let slidesRef;
  let isAutoSlide;
  let countdownInterval;

  const scrollToItem = (index) => {
    const children = Array.prototype.slice.call(slidesRef.children);
    const childWidth = children.length > 0 ? children[0].offsetWidth : 0;

    slidesRef.scrollLeft = index * childWidth;
  };

  const resetTimeInterval = (cb) => {
    clearTimeInterval();
    typeof cb === "function" && cb();
    setTimeInterval();
  };

  const clearTimeInterval = () => {
    // clears current setInterval
    if (isAutoSlide) {
      clearInterval(countdownInterval);
    }
  };

  const setTimeInterval = () => {
    if (isAutoSlide) {
      countdownInterval = setInterval(
        (e) => goToNextSlide(e),
        autoSlideDuration
      );
    }
  };
  const goToSlide = (index) => {
    scrollToItem(index);
    setActiveIndex(index);
  };

  // const goToPrevSlide = (event) => {
  //   event.preventDefault();

  //   let index = activeIndex;

  //   if (index < 1) index = -1;
  //   index = index - 1;

  //   if (isAutoSlide && countdownInterval) {
  //     resetTimeInterval(() => goToSlide(index));
  //   } else {
  //     goToSlide(index);
  //   }
  // };

  const goToNextSlide = (event) => {
    event.preventDefault();

    let index = activeIndex;
    const slidesLength = testimonials.length - 1;

    if (index === slidesLength) index = -1;
    index = index + 1;

    if (event && isAutoSlide && countdownInterval) {
      resetTimeInterval(() => goToSlide(index));
    } else {
      goToSlide(index);
    }
  };
  return (
    <>
      {/* <div className="lg:mr-20 mb-10   lg:flex justify-end hidden lg:pr-[6rem] 2xl:pr-[13rem]">
        <div className="mr-5 cursor-pointer" onClick={(e) => goToPrevSlide(e)}>
          <img src="images/leftarrow.svg" alt="leftarrow" />
        </div>
        <div className="cursor-pointer" onClick={(e) => goToNextSlide(e)}>
          <img src="images/rightarrow.svg" alt="rightarrow" />
        </div>{" "}
      </div> */}
      <div className="lg:ml-20  lg:pl-[8.3rem] 2xl:pl-[13rem] bg-white lg:py-0 lg:mb-24 py-10 lg:flex px-5 lg:px-0 font-mena ">
        <div className="lg:w-[420px] lg:ml-5 lg:mr-5 w-full mb-5">
          <p className="lg:text-5xl text-3xl">
            What businesses like yours say about us
          </p>
        </div>
        <div
          className="w-full lg:flex justify-center lg:overflow-x-scroll no-scrollbar"
          ref={(node) => (slidesRef = node)}
        >
          <div className="lg:whitespace-no-wrap lg:flex">
            {testimonials.map(
              ({ company, name, image, color, colorname, message }, i) => (
                <div
                  className={`bg-[${color}] lg:h-[275px] lg:w-[520px] h-[350px] text-white px-5 py-8 rounded-lg lg:mx-5  mb-5 `}
                  key={i}
                >
                  <p className="text-base mb-8 text-[#DADFFB]  ">{message}</p>
                  <div className="flex items-center gap-3 mt-6">
                    <img src={image} alt="testifier" />
                    <div>
                      <p className={`text-sm text-white `}>{name}</p>
                      <p className={`text-sm text-[#86888A] `}>
                        {company}
                      </p>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default Testimonial;
