import React from "react";
import Slide from 'react-reveal/Slide';

const SectionTwo = () => {
  return (
    <div className="bg-hero-mask bg-hero-mask-sm   bg-cover bg-center bg-no-repeat  bg-[#F2F8FF] lg:mb-32 lg:p-20 font-mena p-10 h-[1000px] lg:h-auto ">
      <div className="container mx-auto">
        {" "}
        <Slide bottom>
          <h2 className="lg:text-7xl lg:w-[900px] text-5xl lg:pl-[8.3rem]">
            Ready to experience the true freedom of money?
          </h2>
        </Slide>
        <div className="mt-14 lg:flex lg:items-center mt-10 lg:w-[700px] lg:pl-[8.3rem]">
          <a
            href="https://lite.xendbridge.com/"
            className="mr-7 px-6 bg-[#0747A6] text-white py-3 text-center rounded-[42px]  font-space lg:w-1/2 w-full hover:opacity-90 block mb-3"
            target="_blank"
            rel="noreferrer"
          >
            Start Trading
          </a>{" "}
          <a
            href="mailto:hello@xendbrigde.com"
            className="mr-7 px-6 bg-[#4C9AFF] text-white py-3 lg:mb-3 rounded-[42px] text-center font-space lg:w-1/2 w-full hover:opacity-90 block"
            target="_blank"
            rel="noreferrer"
          >
            Integrate XendBridge
          </a>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
