import React from "react";
import { socials } from "utils/fx";
import Slide from 'react-reveal/Slide';

const JoinOurCommunity = () => {
  return (
    <div className="lg:flex container mx-auto justify-between items-end gap-10  mb-10 lg:my-20  font-mena p-5 lg:px-[8.3rem] ">
      <div className="lg:w-[300px] w-full text-5xl">Join Our community</div>
      <Slide right cascade>
        <div className="flex lg:gap-10 gap-3 lg:mt-0 mt-5">
          {socials.map(({ image, link }, i) => (
            <a
              href={link}
              className="rounded-full border-2  border-[#4C9AFF] lg:p-3 h-14 w-14 flex items-center justify-center hover:bg-[#4C9AFF]"
              target="_blank"
              rel="noreferrer"
              key={i}
            >
              <img src={image} alt="icon" />
            </a>
          ))}
        </div>
      </Slide>
    </div>
  );
};

export default JoinOurCommunity;
