import React from "react";

const ErrorMessage = ({
  minAmountErrorMessage,
  cryptoCurrency,
  maxAmountErrorMessage,
  maxAmount,
  rateError,
  minAmount
}) => {
  return (
    <div>
      {minAmountErrorMessage && (
        <small className="text-[#bb0100] d-block">
          *Minimum purchase amount: {minAmount} {cryptoCurrency}*
        </small>
      )}

      {maxAmountErrorMessage && (
        <small className="text-[#bb0100]">
          *Maximum purchase amount: {maxAmount} {cryptoCurrency}*
        </small>
      )}

      {rateError && <small className="text-[#bb0100]">*{rateError}*</small>}
    </div>
  );
};

export default ErrorMessage;
