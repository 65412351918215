import React from "react";

const Content = () => {
  return (
    <div className="container mx-auto lg:px-[8.3rem] lg:flex lg:py-10 py-10 px-5 relative text-[#080A0C]  ">
      <div className="container  m-0 py-lg-3 py-sm-0 font-[400px] font-[14px]">
        <div className=" mb-5 ">
          <p className="mb-5 text-base">
            This Privacy Policy governs your use and access to our services and
            your interaction with XendBridge. Xend Labs Limited (XendFinance)
            the creator of XendBridge is committed to protecting your privacy at
            all times and to that extent, we have prepared this Privacy Policy
            to shed some light on how we interact and process your personal
            data.
          </p>

          <h6 className=" mb-1"> Definition of Terms</h6>
          <p className="mb-2">
            In this Privacy Policy, we used, inter alia, the following terms:
            ‘Personal Data’ means any information or data relating to an
            identified or identifiable natural person or legal person, public
            authority, agency, or body (“data subject”); an identifiable natural
            person or legal person, public authority, agency or body is a person
            or legal person, public authority, agency or body who can be
            identified, directly or indirectly, in particular by reference to an
            identifier such as a personal name, business/agency name, an
            identification number or incorporation number, location data or an
            online identifier.
          </p>
          <p className="mb-3">
            ‘Data Subject’ is any User or intending User of our services who is
            identified or an identifiable natural person or legal person, public
            authority, agency, or body, whose personal data is processed.
          </p>
          <p className="mb-3">
            ‘Processing’ is any operation or set of operations that are
            performed on personal data or on sets of personal data, whether or
            not by automated means, such as collection, recording, organization,
            structuring, maintaining, storage, adaptation or alteration,
            retrieval, consultation, use, disclosure by transmission,
            dissemination or otherwise making available, alignment or
            combination, restriction, erasure or destruction.
          </p>
          <p className="mb-3">
            ‘Third Party’ means a natural or legal person, public authority,
            agency, or body other than us, the data subject, and persons who
            though not us but are under our direct authority authorized to
            process personal data.
          </p>
          <p className="mb-3">
            {" "}
            ‘Consent’ of the data subject is any freely given, specific,
            informed, and unambiguous indication of the data subject's wishes by
            which he or she, by a statement or by clear affirmative action,
            signifies agreement to the processing of personal data relating to
            him, her or they. Clear affirmative action, signifies agreement to
            the processing of personal data relating to him, her, or they.
          </p>
          <p className="mb-3">“API” Application Programming Interface.</p>

          <div>
            <h6 className="">Introduction</h6>
            <p className="mb-3">
              {" "}
              At Xend Labs Limited (XendFinance), we believe that your Personal
              Data/information is of high value and should be handled with care.
              This Privacy Policy has been compiled to better inform the Data
              Subject of how the information provided to us while interacting
              with our services will be processed, as well as how we secure that
              Personal Data. By making use of our services, you consent to the
              Processing and use of your Personal Data as noted in this Privacy
              Policy. Please read this Privacy Policy carefully to get a clear
              understanding.{" "}
            </p>
            <h6 className=""> GDPR Conformity </h6>
            <p className="mb-3">
              {" "}
              The processing of the personal data of Data Subjects from the
              European Union shall always be in line with the General Data
              Protection Regulation (GDPR), and in accordance with the
              country-specific data protection regulations applicable to the
              services you are in use of at any specific time.
            </p>
            <h6 className="">
              What Information do we obtain and how is it Used?
            </h6>
            <h6 className=""> User-Provided Information</h6>
            We Process the information you provide when you register with us to
            access our services. When you register with us and use our services,
            you will be generally expected to provide the following information:
            <ul style={{ listStyleType: "initial" }} className="ml-10">
              <li>Your username;</li>
              <li>Your business name or agency name;</li>
              <li>Your business address;</li>
              <li>Your mobile number;</li>
              <li>Your email address;</li>
              <li>Any applicable referral code</li>
              <li>ISO code</li>
              <li>Your business registration document</li>
              <li>
                Your interaction/transaction-related information with our API,
                such as your activities relating to your integration of our API;
              </li>
              <li>
                The information you provide us when you contact our customer
                care;
              </li>
            </ul>
            We may also use the information provided by you to contact you from
            time to time to provide you with important updates and/or
            information, required notices, and marketing promotions.
          </div>
          <br />
          <div>
            <h6 className="">
              {" "}
              Automatically Collected Personal Data/Information{" "}
            </h6>
            <p className="mb-3">
              In addition, we may collect certain Personal Data from you
              automatically as you interact with our services, including but not
              limited to, information collected by our servers which includes
              your operating system, the type of internet browser you use, your
              device’s unique ID, the Internet Protocol (IP) address of your
              device, your network provider, date/time logs/timestamps.
            </p>
            <h6 className="">
              Does the App collect precise real-time location information of
              your device?
            </h6>
            <p className="mb-3">
              When you make use of our services, we may use GPS technology (or
              other similar technology) to determine your current location in
              order to determine the city you are located. We may also display a
              location map with relevant advertisements. We will not share your
              current location with other users or third parties.
            </p>
            <p className="mb-3">
              If you do not want us to use your location for the purposes set
              forth above, you should turn off the location services for your
              device or toggle off this option as a feature that may be provided
              in our services.
            </p>
            <h6 className="">
              Do third parties see and/or have access to information obtained by
              us?
            </h6>
            <p className="mb-3">
              No. We will not share your information with third parties.
            </p>
            <p className="mb-3">
              We may disclose User Provided as well as Automatically Collected
              Information under the following circumstances:
              <ul>
                <li>
                  As required by law, such as to comply with a subpoena or
                  similar legal process;
                </li>
                <li>
                  When we believe in good faith that disclosure is necessary to
                  protect our rights, protect your safety or the safety of
                  others, investigate fraud, or respond to a government request;
                </li>
                <li>
                  If we are involved in a merger, acquisition, or sale of all or
                  a portion of our assets, you will be notified via email and/a
                  prominent notice will be provided on our Website indicating
                  any change in ownership, as well as any choices you may have
                  regarding this information.
                </li>
              </ul>
            </p>
          </div>
          <br />
          <div>
            {" "}
            <h6 className=""> What are your opt-out rights?</h6>
            <p className="mb-3">
              You can stop all collection of information by our applications
              easily by deleting your account with us through the option
              provided for it in our services. You can also request to opt-out
              via email, at Hello@Xendbridge.com
            </p>
            <h6 className="">
              Data Retention Policy and how we Manage Your Information
            </h6>
            <p className="mb-3">
              We will retain User Provided data for as long as you use our
              services and for a reasonable time thereafter. We will retain
              Automatically Collected information for up to 24 months and
              thereafter may store it in aggregate. If you’d like us to delete
              your Personal Data, please contact us at Hello@Xendbridge.com and
              we will respond within a reasonable time. Please note that some or
              all of your Personal Data may be required in order for you to
              access our services and for our application to function
              effectively.
            </p>
            <h6 className="">Privacy for Children</h6>
            <p className="mb-3">
              Children below the age of 13 are restricted from making use of our
              API to conform to the COPPA (Children Online Privacy Protection
              Act). Thus, we do not knowingly solicit data from or market our
              services to children under the age of 13. If a parent or guardian
              becomes aware that his or her child has provided us with
              information without their consent, he or she should contact us at
              Hello@Xendbridge.com. We will delete such information from our
              database within a reasonable time.
            </p>
            <h6 className="">Security</h6>
            <p className="mb-3">
              We are concerned about safeguarding your Personal Data. We provide
              physical, electronic, and procedural safeguards to protect the
              information we process and maintain. We have taken the following
              steps in these regards:
              <ul>
                <li>
                  We have incorporated our security team from day one during the
                  development of our services;
                </li>
                <li>
                  We have tested and will keep testing our application for any
                  possible security loophole or breach;
                </li>
                <li>
                  We have utilized secure data transmission protocols to ensure
                  the safety of your information;
                </li>
                <li>
                  We have implemented tamper protection to ensure the safety of
                  your information,
                </li>
                <li>
                  We limit access to your information to authorized employees
                  and contractors only, who need to know that information in
                  order to operate, develop or improve our services.
                </li>
              </ul>
            </p>
          </div>
          <br />
          <p className="mb-3">
            {" "}
            Please note that, although we endeavor to provide reasonable
            security for the information we process and maintain; no security
            system is infallible, and as such all potential security breaches
            may not be eliminated.
          </p>
          <h6 className="">Changes</h6>
          <p className="mb-3">
            This Privacy Policy may be updated from time to time for several
            reasons. We will notify you of any changes to this Privacy Policy by
            replacing this Privacy Policy with the updated one and we will
            thereafter inform you via email. You are advised to consult this
            Privacy Policy regularly in other to track any changes. Note that
            the continued use of our services after the update is deemed
            approval of any changes made to this Privacy Policy.
          </p>
          <h6 className="">Your Consent</h6>
          <p className="mb-3">
            By using our services, A Data Subject is consenting to our
            processing of their Personal Data as set forth in this Privacy
            Policy as it is now and/or as may be amended in due time.
          </p>
          <h6 className="">Contact us</h6>
          <p className="mb-3">
            If there are any questions regarding this privacy policy, you may
            contact us using the information below.
          </p>
          <p className="mb-3">Hello@Xendbridge.com</p>
        </div>
      </div>
    </div>
  );
};

export default Content;
