import React from "react";

const Rate = ({ startingRate, cryptoCurrency, fiatCurrency, bestRate }) => {
  return (
    <div className="flex justify-between font-mena">
      {" "}
      {startingRate && (
        <div className="rate ">
          <span className="text-[#7C7C7C] lg:text-sm text-xs">Starting Rate</span>
          <p className="text-[#5F5F5F] lg:text-sm text-xs">
            1 {cryptoCurrency} ~ {startingRate} {fiatCurrency}
          </p>
        </div>
      )}
      {bestRate && (
        <div className="rate ">
          <span className="text-[#7C7C7C] lg:text-sm text-xs">Best Rate</span>
          <p className="text-[#5F5F5F] lg:text-sm text-xs">
            1 {cryptoCurrency} ~ {bestRate} {fiatCurrency}
          </p>
        </div>
      )}
    </div>
  );
};

export default Rate;
