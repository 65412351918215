import React from "react";
import Slide from "react-reveal/Slide";

const SectionFive = () => {
  return (
    <div className="bg-hero-mask-blue bg-hero-mask-blue-sm  font-mena bg-cover bg-center bg-no-repeat  bg-[#fff] lg:mb-10 lg:p-20 font-mena py-10 px-5 h-[1000px] lg:h-auto ">
      <Slide left>
        <div className="container mx-auto lg:px-[8.3rem] ">
          <div className="lg:flex items-end mb-4">
            <h5 className="text-[#080A0C] text-4xl font-bold mr-2">
              Xend Bridge
            </h5>{" "}
            <div className="text-[#0747A6]">For Business</div>
          </div>
          <h2 className="lg:text-6xl lg:w-[600px] font-normal text-6xl">
            Check our business solution.
          </h2>
          <p className="lg:w-[500px] text-xl text-[#525252] mt-10">
            Empower your business with the XendBridge API. Get all the tools you
            need to provide instant payment solutions to your customers.
          </p>
          <div className="mt-8 lg:flex lg:items-center lg:mt-10 lg:w-[500px]">
            <a
              href="https://doc.xendbridge.com/"
              className="mr-7 px-6 bg-[#0747A6] text-white py-3 text-center rounded-[42px]  font-space lg:w-1/2 w-full hover:opacity-90 block mb-3"
              target="_blank"
              rel="noreferrer"
            >
              Integrate XendBridge
            </a>{" "}
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default SectionFive;
