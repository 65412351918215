import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { navLinks } from "utils/fx";
import Slide from "react-reveal/Slide";

const MobileHeader = ({ is404 }) => {
  let location = useLocation();
  const isBlueBg =
    location.pathname === "/business" ||
    location.pathname === "/privacy-policy";

  const [toggleMenu, setToggleMenu] = useState(false);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <>
      {" "}
      <nav
        className={`${
          isBlueBg ? "bg-[#0747A6]" : "bg-white"
        } container mx-auto py-5 lg:hidden flex px-8 justify-between font-mena font-normal items-center lg:fixed z-20`}
      >
        {isBlueBg ? (
          <a href="/">
            <img src="images/logo-1.svg" alt="logo" />
          </a>
        ) : (
          <a href="/">
            <img src="images/logo.svg" alt="logo" />
          </a>
        )}
        {!is404 && (
          <>
            {toggleMenu ? (
              <>
                {isBlueBg ? (
                  <div
                    className="rounded-full h-[30px] w-[30px] flex bg-[#fff] text-[#0747A6] items-center justify-center z-30"
                    onClick={toggleNav}
                  >
                    X
                  </div>
                ) : (
                  <div
                    onClick={toggleNav}
                    className="rounded-full h-[30px] w-[30px] flex bg-[#0747A6] text-white items-center justify-center z-30"
                  >
                    X
                  </div>
                )}
              </>
            ) : (
              <>
                {" "}
                {isBlueBg ? (
                  <img
                    src="images/hamburger-white.svg"
                    alt="logo"
                    onClick={toggleNav}
                  />
                ) : (
                  <img
                    src="images/hamburger.svg"
                    alt="logo"
                    onClick={toggleNav}
                  />
                )}
              </>
            )}
          </>
        )}
      </nav>
      {toggleMenu && (
        <div
          className={`h-[90vh] pt-[20px] absolute w-full px-8  ${
            isBlueBg ? "bg-[#0747A6] text-white" : "bg-white text-[#0747A6] "
          } z-10`}
        >
          {" "}
          <Slide right cascade>
            <ul>
              {navLinks.map(({ title, path, target }, index) => (
                <li className="mb-4" key={index}>
                  {" "}
                  <a
                    href={path}
                    className={` hover:text-[#4C9AFF] text-base ${
                      location.pathname === path
                        ? "text-[#4C9AFF]"
                        : `${isBlueBg ? "text-white" : "text-black"}`
                    }`}
                    target={target}
                  >
                    {title}
                  </a>
                </li>
              ))}

              {/* <li className="mb-5 mt-16 font-bold">
                {" "}
                <span
                  className={`${isBusiness ? "text-white" : "text-black"} `}
                >
                  En
                </span>{" "}
              </li> */}
              <li className="mb-8 font-bold">
                {" "}
                <a
                  href="https://integrationssandbox.xendbridge.com/thirdparty/"
                  className={`${isBlueBg ? "text-white" : "text-black"} `}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Sign in
                </a>
              </li>
              <li className="mb-5 ">
                {" "}
                <a
                  href="https://integrationssandbox.xendbridge.com/thirdparty/register"
                  className=" px-6 bg-[#4C9AFF] text-white py-3 rounded-[42px] hover:opacity-90"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Get started
                </a>{" "}
              </li>
            </ul>
          </Slide>
        </div>
      )}
    </>
  );
};

export default MobileHeader;
