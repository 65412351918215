import React from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

/**
 * This component renders a backdrop that is absolute positioned and fixed to the screen.
 * Can be used to render a modal or a menu which stops the user from interacting with the rest of the page.
 */
function BackDrop({
  isOpen,
  onVisibleStyle,
  children,
  className,
  unMountChildren,
  ...props
}) {
  if (unMountChildren && !isOpen) {
    return null;
  }
  return (
    <motion.div
      layoutId="backdrop"
      initial={"hidden"}
      exit={{ opacity: 0 }}
      className={twMerge(
        "flex flex-col justify-center items-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50",
        className
      )}
      animate={isOpen ? "visible" : "hidden"}
      variants={{
        hidden: { opacity: 0, display: "none" },
        visible: { opacity: 1, display: "flex", ...onVisibleStyle },
      }}
      {...props}
    >
      {children}
    </motion.div>
  );
}

export default BackDrop;
