import Header from "components/Header";
import React from "react";
import Page404Component from "components/Page404/index";

const Page404 = () => {
  return (
    <>
      <Header is404={true} />
      <Page404Component />
    </>
  );
};

export default Page404;
