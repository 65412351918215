export const cryptoNetworks = [
  {
    name: 'BEP20',
    symbolUrl:
      'https://res.cloudinary.com/dyyj2hjf5/image/upload/v1706618678/binance-coin-bnb-logo-CD94CC6D31-seeklogo.com_bxyvsw.png',
  },
  {
    name: 'ERC20',
    symbolUrl:
      'https://res.cloudinary.com/dyyj2hjf5/image/upload/v1668785496/untitled_zzns3h.png',
  },
  {
    name: 'POLYGON',
    symbolUrl:
      'https://res.cloudinary.com/dyyj2hjf5/image/upload/v1668773793/polygon_detdu6.png',
  },
  // {
  //   name: 'TRON',
  //   symbolUrl:
  //     'https://res.cloudinary.com/dyyj2hjf5/image/upload/v1670234612/download-removebg-preview_pmpcrj.png',
  // },
  {
    name: 'ARBITRUM',
    symbolUrl:
      'https://res.cloudinary.com/dyyj2hjf5/image/upload/v1706618512/arbitrum-arb-logo_l0xk7y.png',
  },
];

export const navLinks = [
  { title: 'Home', path: '/' },
  { title: 'Individual', path: '/individual' },
  { title: 'Business', path: '/business' },
  { title: 'OTC Desk', path: '/otc-desk' },
  { title: 'Help', path: 'mailto:hello@xendbridge.com' },
  { title: 'Blog', path: 'https://medium.com/xendfinance', target: '_blank' },
];

export const partners = [
  { image: 'images/Wircrypt.svg', link: 'https://wicrypt.com/' },
  { image: 'images/Xend-finance.svg', link: 'https://xend.finance/' },
  { image: 'images/Switchwallet.svg', link: 'https://switchwallet.io/' },
  { image: 'images/Lazerpay.svg', link: 'https://lazerpay.finance/' },
  { image: 'images/Moneypigeon.svg', link: 'https://moneypigeon.com/' },
  { image: 'images/risevest-copy.svg', link: 'https://risevest.com/' },
];

export const about = [
  {
    image: 'images/transfer.svg',
    title: 'Express P2P Transfers',
    description:
      'Transactions on XendBridge are quick and easy. Our well-structured P2P system powered by a pool of liquidity providers allows for the completion of transactions within 0-15 minutes.',
  },
  {
    image: 'images/security.svg',
    title: '100% Security',
    description:
      'XendBridge provides your business with a secure and reliable way to make transactions. Our infrastructure is frequently audited by leading auditing firms in the industry.',
  },
  {
    image: 'images/tag.svg',
    title: '0% Transaction fees',
    description:
      'XendBridge is the most affordable peer-to-peer system for your business. Integrate the XendBridge API at zero cost. You also enjoy zero transaction fees for you and your users.',
  },
  {
    image: 'images/send.svg',
    title: 'Send Money Across Borders',
    description:
      'Send and receive instant and secure borderless payments at your fingertips.',
  },
  {
    image: 'images/Chain.svg',
    title: 'Multi-chain Support',
    description:
      'XendBridge is supported across different network chains, including BSC, Ethereum, and Polygon. You can carry out transactions without any limitations.',
  },
  {
    image: 'images/user.svg',
    title: 'Trusted and Verified Liquidity Providers',
    description:
      'Our liquidity providers are properly vetted before they are introduced into the system. Our detailed compliance process ensures that every LP is legitimate.',
  },
];

export const socials = [
  { link: 'https://t.me/Xendbridge', image: 'images/Telegram.svg' },
  { link: 'https://twitter.com/xendbridge', image: 'images/Twitter.svg' },

  {
    link: 'https://www.facebook.com/profile.php?id=100087009951659',
    image: 'images/Facebook.svg',
  },
  {
    link: 'https://www.instagram.com/xendbridge/',
    image: 'images/Instagram.svg',
  },
  { link: 'mailto:Hello@Xendbridge.com', image: 'images/Email.svg' },

  // { link: "/", image: "images/Linkedin.svg" },
  // { link: "/", image: "images/Youtube.svg" },
];

export const Cryptos = [
  { image: 'images/Crypto1.svg' },
  { image: 'images/Crypto2.svg' },
  { image: 'images/Crypto3.svg' },
  { image: 'images/Crypto4.svg' },
  { image: 'images/Crypto5.svg' },
  { image: 'images/Crypto6.svg' },
  { image: 'images/Crypto7.svg' },
  { image: 'images/Crypto8.svg' },
  { image: 'images/Crypto9.svg' },
];

export const footer = [
  [
    { link: '/business', title: 'For Business' },
    { link: '/individual', title: 'For Individual' },
  ],
  [
    { link: '/#features', title: 'Features' },
    { link: '/#partners', title: 'Partners' },
  ],
  [
    { link: 'https://lite.xendbridge.com/', title: 'XendBridge Lite' },
    { link: 'https://doc.xendbridge.com/', title: 'XendBridge API' },
  ],
  [
    { link: 'https://doc.xendbridge.com/', title: 'API Documentation' },
    // { link: "/", title: "Media Kit" },
    { link: 'https://learn.xend.finance/', title: 'Xend Learn' },
  ],
  [
    { link: 'mailto:Hello@Xendbridge.com', title: 'hello@xendbridge.com' },
    { link: 'tel:+234 1888 3340', title: 'Tel: 018883340' },
  ],
];

export const testimonials = [
  {
    message:
      '“Operating as a lean startup, the solutions offered with XendBridge allows Lazerpay to save time and major development costs. We can easily integrate their API and infrastructure and focus on scaling our payment platform for businesses around the world.”',
    name: 'Emmanuel Njoku',
    company: 'Lazerpay Finance',
    image: 'images/testifier.svg',
    color: '#080A0C',
    colorname: '#86888A',
  },
  // {
  //   message:
  //     "“Operating as a lean startup,the solutions offered with XendBridge allows Lazerpay to save time and major development costs. We can easily integrate their API and infrastructure and focus on scaling our payment platform for businesses around the world.”",
  //   name: "Emmanuel Njoku",
  //   company: "Lazerpay Finance",
  //   image: "images/testifier2.svg",
  //   color: "#FFAB19",
  //   colorname: "#818181",
  // },
];

export const autoSlideDuration = 6000;

export const liteFeatures = [
  {
    title: 'Buy',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis,',
  },
  {
    title: 'Sell',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis,',
  },
  {
    title: 'Swap',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis,',
  },
];

export const aboutBusiness = [
  {
    image: 'images/api.svg',
    title: 'Seamless integration process',
    description:
      'Seamlessly integrate with the XendBridge API in less than a week. The integration has been simplified to get you started on satisfying your customers in no time.',
  },
  {
    image: 'images/tag.svg',
    title: 'Transaction fees: Zero',
    description:
      'XendBridge is the most affordable peer-to-peer system for your business. Integrate the XendBrIdge API at zero cost. ',
  },
  {
    image: 'images/cog.png',
    title: 'Customizable features',
    description:
      "Integrate with the XendBridge API using your brand’s unique design. You can easily customize its features to fit your brand identity. It can be designed to blend with your brand's design.",
  },
  {
    image: 'images/dashboard1.svg',
    title: 'An extensive dashboard that keeps you in control',
    description:
      'Our dashboard keeps you in control. You can manage all trades independently, view wallet balances, create reports, and approve transfers.',
  },
  {
    image: 'images/security.svg',
    title: 'Support for all types of businesses',
    description:
      'XendBridge supports both lean startups and large enterprises. Your customers can easily transact with XendBridge, no matter how large your customer base is.',
  },
  {
    image: 'images/user.svg',
    title: '24/7 support',
    description:
      'Live chat and email support are available 24/7, so you never have to worry about unforeseen challenges.',
  },
];

export const aboutOTC = [
  {
    image: 'images/step1.svg',
    title: 'Fill out the account request form',
    description: `Before we can open an OTC account for you, please fill out this form`,
    link: 'https://docs.google.com/forms/d/e/1FAIpQLSeDQ5VB3F6aoReV3DtXDvRnpePQHulFUHWTpqN9XsmMKt37Ow/viewform',
    linkTitle: 'here',
  },
  {
    image: 'images/step2.svg',
    title: 'Receive a quote for your trade',
    description:
      'We will connect you with one of our expert fund managers to start executing your orders. They will provide you with any support you may need. Feel free to reach out to them at any time',
  },
  {
    image: 'images/step3.svg',
    title: 'Complete KYC',
    description: 'Make sure to complete your',
    link: 'https://docs.google.com/forms/d/e/1FAIpQLSddIMUE57hGnuRfxYPs0Lx2unmznbFrqkUE1-h6txoq-NKCYA/viewform',
    linkTitle: 'KYC/KYB',
  },
  {
    image: 'images/step4.svg',
    title: 'Settlement of Fiat/Crypto',
    description:
      'Once payment details have been confirmed, your transaction will begin. We provide a settlement time of 24 - 72 hours.',
  },
];

export const accordions = [
  {
    title: 'How do I get started?',
    content: `To get started, click on the "Request a Quote" button.`,
  },
  {
    title: 'Is there a limit to the amount I can trade?',
    content: 'There are no limits to the amount you can trade.',
  },
  {
    title: 'What are the terms and eligibility?',
    content: `To get started, click on the "Request a Quote" button.`,
  },
];

export const orderTypes = ['Buy', 'Sell', 'Swap'];

export const xendLiteFeatures = [
  'Buy crypto via bank transfer and receive crypto or fiat in your bank account.',
  'Complete transactions in just a few minutes.',
  'Enjoy the cheapest rates in the industry.',
  'Switch between your favourite tokens easily with our swap feature.',
];
