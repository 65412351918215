import React from "react";

const News = () => {
  return (
    <div className=" bg-[#0747A6] mb-10  " id="blog">
      <div className="lg:flex lg:px-[8.3rem] lg:gap-20 gap-10 container mx-auto font-mena lg:py-20 p-5 ">
        <h5 className="text-white text-5xl lg:text-right text-center ">
          Latest News
        </h5>
        <div className="text-2xl lg:mt-0 mt-5">
          <div className="flex text-[#4C9AFF] items-center mb-5">
            <div className="w-36 lg:w-28 lg:text-base text-sm">
              Aug 05, 2022
            </div>{" "}
            <div className="h-[4px] w-[30px] bg-[#4C9AFF] lg:mr-8 lg:ml-3  mr-5" />
            <div className="w-[350px] text-white ">
              <a
                href="https://www.techinafrica.com/xend-introduces-its-cryptocurrency-payment-api-and-wallet-infrastructure/"
                className="w-[500px]  text-white hover:opacity-80 hover:underline"
                target={"_blank"}
                rel="noreferrer"
              >
                {" "}
                <p className="lg:text-md text-[1rem] leading-[20px]">
                  Xend introduces its cryptocurrency payment API and wallet
                  infrastructure - Tech In Africa
                </p>
              </a>
            </div>
          </div>
          <div className="flex text-[#4C9AFF] items-center mb-5">
            <div className="w-36 lg:w-28 lg:text-base text-sm">
              July 26, 2022
            </div>{" "}
            <div className="h-[4px] w-[30px] bg-[#4C9AFF] lg:mr-8 lg:ml-3  mr-5" />
            <div className="w-[350px] text-white ">
              <a
                href="https://www.bsc.news/post/xend-expands-reach-in-africa-with-new-products"
                target={"_blank"}
                rel="noreferrer"
                className="w-[500px]  text-white hover:opacity-80 hover:underline"
              >
                {" "}
                <p className="lg:text-md text-[1rem] leading-[20px]">
                  Xend Expands Reach in Africa With New Products
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
