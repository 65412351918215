import React from "react";
import Fade from "react-reveal/Fade";
import { xendLiteFeatures } from "utils/fx";

const SectionThree = () => {
  return (
    <div className="container mx-auto lg:my-20 lg:px-[8.3rem] mb-20 font-mena px-5">
      <div className="lg:grid lg:grid-cols-2 grid-cols-1 gap-10 lg:mt-10 mt-6">
        <div>
          {" "}
          <div className="flex items-end mb-4">
            <h5 className="text-[#080A0C] text-4xl font-bold mr-2">
              XendBridge
            </h5>{" "}
            <span className="text-[#4C9AFF]">Lite</span>
          </div>
          <h4 className="text-3xl mb-8">Simplified Crypto Experience</h4>
          <Fade left cascade>
            <div className=" mb-10">
              <div className="mb-6">
                <h6 className="text-xl mb-8 text-[#080A0C] lg:w-[370px]">
                  Buy, sell, and swap cryptocurrencies quickly, easily, and
                  securely using XendBridgeLite.{" "}
                </h6>
                {xendLiteFeatures.map((item, i) => (
                  <div className="flex items-center gap-4 mb-3" key={i}>
                    <img src="images/checkmark-star.svg" alt="checkmark" />
                    <p className="text-lg text-[#525252] lg:w-[440px] ">
                      {item}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </Fade>
        </div>
        <div className="relative mb-10">
          <Fade right>
            <img src="images/Asset.svg" alt="Union" />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
