import React from "react";
import Slide from "react-reveal/Slide";

const SectionFive = () => {
  return (
    <div className="bg-hero-mask bg-hero-mask-sm   bg-cover bg-center bg-no-repeat  bg-[#F2F8FF] lg:mb-0 lg:p-20 font-mena p-10 h-[1000px] lg:h-auto ">
      <div className="container mx-auto lg:px-[8.3rem]">
        {" "}
        <Slide bottom>
          <h2 className="lg:text-7xl lg:w-[800px] text-5xl">
            Execute large OTC orders seamlessly and privately{" "}
          </h2>
        </Slide>
        <div className="mt-8 lg:flex lg:items-center lg:mt-20 lg:w-[500px]">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeDQ5VB3F6aoReV3DtXDvRnpePQHulFUHWTpqN9XsmMKt37Ow/viewform"
            className="mr-7 px-6 bg-[#0747A6] text-white py-3 text-center rounded-[42px]  font-space lg:w-1/2 w-full hover:opacity-90 block mb-3"
            target="_blank"
            rel="noreferrer"
          >
            Request a Quote{" "}
          </a>{" "}
          <a
            href="tel:018883340"
            className="mr-7 px-6 bg-[#4C9AFF] text-white py-3 lg:mb-3 rounded-[42px] text-center font-space lg:w-1/2 w-full hover:opacity-90 block"
            target="_blank"
            rel="noreferrer"
          >
            Phone Support{" "}
          </a>
        </div>
      </div>
    </div>
  );
};

export default SectionFive;
