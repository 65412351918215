import React from "react";
import Header from "components/Header";
import JoinOurCommunity from "components/JoinOurCommunity";
import Footer from "components/Footer";
import SectionSeven from "components/Business/SectionSeven";
import SectionEight from "components/Business/SectionEight";
import SectionFive from "components/Business/SectionFive";
import SectionSix from "components/Business/SectionSix";
// import SectionFour from "components/Business/SectionFour";
import SectionThree from "components/Business/SectionThree";
import SectionTwo from "components/Business/SectionTwo";
import SectionOne from "components/Business/SectionOne";

const Business = () => {
  return (
    <>
      <Header />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      {/* <SectionFour /> */}
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <SectionEight />
      <JoinOurCommunity />
      <Footer />
    </>
  );
};

export default Business;
