import React, { useRef, useState } from "react";
import { accordions } from "../../utils/fx";
import Fade from "react-reveal/Fade";

const FAQs = () => {
  return (
    <div className="bg-[#D7E9FF] text-center lg:px-0 px-4 mb-20 py-20 font-mena">
      <h3 className="text-[#080A0C] lg:text-5xl text-4xl lg:w-[450px] mb-10 mx-auto">
        OTC Frequently Asked Questions
      </h3>
      <div className="flex flex-col mx-auto lg:w-[600px]">
        {" "}
        {accordions.map((item, i) => (
          <Accordion title={item.title} content={item.content} key={i} />
        ))}{" "}
      </div>
    </div>
  );
};

const Accordion = ({ title, content }) => {
  const [isOpened, setOpened] = useState(false);
  const [height, setHeight] = useState("0px");
  const contentElement = useRef(null);

  const HandleOpening = () => {
    setOpened(!isOpened);
    setHeight(!isOpened ? `${contentElement.current.scrollHeight}px` : "0px");
  };
  return (
    <Fade bottom>
      <div
        onClick={HandleOpening}
        className="bg-white my-2 rounded-[30px] lg:text-base text-sm"
      >
        <div className="flex flex-row justify-between items-center text-[#080A0C] py-3 lg:px-6 px-4 cursor-pointer">
          <h4 className="font-semibold text-base lg:text-xl  text-left mb-0">{title}</h4>
          <img
            src="images/opened.svg"
            alt="opened"
            className={isOpened ? "transform rotate-180" : ""}
          />
        </div>
        <div
          ref={contentElement}
          style={{ height: height }}
          className=" text-sm text-left px-5   overflow-hidden transition-all duration-200"
        >
          <p className="lg:pb-4 pb-5 ">{content}</p>
        </div>
      </div>{" "}
    </Fade>
  );
};

export default FAQs;
