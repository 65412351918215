import React from 'react';
import { footer } from 'utils/fx';
import Marquee from 'react-fast-marquee';

const Footer = () => {
  return (
    <div className="lg:mt-20 mt-10 font-mena relative">
      <Marquee speed={80} gradientWidth={0}>
        <img
          src="images/XendBridge.svg"
          className="lg:h-[320px] h-[100px] mr-5 opacity-20"
          alt="logo"
        />
      </Marquee>

      <div className=" ">
        <div
          className={`absolute z-10  w-full bg-hero-footer  bg-hero-footer-sm bg-cover bg-center bg-no-repeat  lg:top-[150px] top-[60px] lg:pt-[120px] pt-[10px] text-white lg:px-[12rem] 2xl:px-[18rem] `}
        >
          <div className=" container lg:px-0 relative lg:flex flex-wrap justify-between  px-16 ">
            <div className="lg:w-1/5 w-full pt-28 text-[#9F9F9F]">
              <img src="images/logo-white.svg" alt="logo" />
              <p className="text-sm lg:text-md mt-4">
                A Product of Xend Finance
              </p>
            </div>
            <div className="text-[#E5E5E5]">
              <h6 className="lg:text-xl text-lg lg:mb-5  mb-3 lg:mt-0 mt-5">
                Company
              </h6>
              {footer[0].map(({ title, link }, i) => (
                <a
                  href={link}
                  className="block mb-1 hover:opacity-80 lg:text-base text-sm"
                  key={i}
                >
                  {title}
                </a>
              ))}
              <a
                href="/otc-desk"
                className="block lg:mb-1 hover:opacity-80 mb-2 lg:text-base text-sm"
              >
                OTC Desk
              </a>
              {footer[1].map(({ title, link }, i) => (
                <a
                  href={link}
                  className="block mb-1 hover:opacity-80 lg:text-base text-sm"
                  key={i}
                >
                  {title}
                </a>
              ))}
            </div>
            <div className="text-[#E5E5E5]">
              <h6 className="lg:text-xl text-lg lg:mb-5  mb-3 lg:mt-0 mt-5">
                Products
              </h6>
              {footer[2].map(({ title, link }, i) => (
                <a
                  href={link}
                  className="block mb-1 hover:opacity-80 lg:text-base text-sm"
                  key={i}
                >
                  {title}
                </a>
              ))}
            </div>
            <div className="text-[#E5E5E5]">
              <h6 className="lg:text-xl text-lg lg:mb-5  mb-3 lg:mt-0 mt-5">
                Help & Resocures
              </h6>
              {footer[3].map(({ title, link }, i) => (
                <a
                  href={link}
                  className="block mb-1 hover:opacity-80 lg:text-base text-sm"
                  key={i}
                >
                  {title}
                </a>
              ))}
            </div>
            <div className="text-[#E5E5E5]">
              <h6 className="lg:text-xl text-lg lg:mb-5  mb-3 lg:mt-0 mt-5">
                Get In Touch
              </h6>
              {footer[4].map(({ title, link }, i) => (
                <a
                  href={link}
                  className="block mb-1 hover:opacity-80 lg:text-base text-sm"
                  key={i}
                >
                  {title}
                </a>
              ))}
            </div>
          </div>
          <div className=" container  lg:px-0  lg:mt-[110px] mt-[80px] lg:flex justify-between items-center pb-5 text-[#6A6A6A] text-center  px-5 lg:text-base text-sm">
            <div className="order-1 lg:mb-0 mb-5 flex justify-center lg:gap-10 gap-5 lg:order-2">
              <a href="/terms-and-conditions" className="hover:opacity-80">
                {' '}
                Terms of Use
              </a>{' '}
              <a href="/privacy-policy" className="hover:opacity-80">
                Privacy Policy
              </a>{' '}
              {/* <a href="/" className="hover:opacity-80">
                Cookie Policy
              </a> */}
            </div>{' '}
            <p className="lg:mb-0  mb-5 lg:order-1 order-2">
              Copyright © {new Date().getFullYear()} XendBridge All Rights
              Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
