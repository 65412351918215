import React from "react";

const SectionFive = () => {
  return (
    <div className="container mx-auto lg:px-[8.3rem] px-5 mb-20 lg:mt-40 font-mena ">
      <div className=" lg:flex">
        <div className="lg:w-1/2 mb-10">
          <h4 className="text-[#080A0C] text-4xl font-bold w-[280px]">
            {" "}
            Who can use XendBridge?
          </h4>
          <p className="text-[#525252] text-lg  lg:w-[450px] mt-5">
            Our P2P payment API was built for easy on-ramp and off-ramp of
            crypto.
          </p>
        </div>

        <div className="lg:w-1/2">
          <h4 className="text-[#080A0C] text-4xl mb-2">Exchanges</h4>
          <h4 className="text-[#080A0C] text-4xl mb-2">Defi Institutions</h4>
          <h4 className="text-[#080A0C] text-4xl mb-2">Merchants</h4>
        </div>
      </div>
    </div>
  );
};

export default SectionFive;
