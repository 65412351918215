import React from "react";
import Header from "components/Header";
import JoinOurCommunity from "components/JoinOurCommunity";
import Footer from "components/Footer";
import SectionFour from "components/Individual/SectionFour";
import SectionFive from "components/Individual/SectionFive";
import Crytocurrencies from "components/Individual/Crytocurrencies";
import SectionOne from "components/Individual/SectionOne";
import SectionTwo from "components/Individual/SectionTwo";
import SectionThree from "components/Individual/SectionThree";

const Individual = () => {
  return (
    <>
      <Header />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <Crytocurrencies />
      <SectionFour />
      <SectionFive />
      <JoinOurCommunity />
      <Footer />
    </>
  );
};

export default Individual;
