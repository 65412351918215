import React, { useState, useEffect } from "react";
import { useGetSupportedCurrenciesMutation } from "services/config.service";
import { orderTypes } from "../../utils/fx";
import BuyOrderForm from "./Form/BuyOrderForm";
import { saveSupportedCurrencies } from "store/slice/config/config.slice";
import { useDispatch, useSelector } from "react-redux";
import SellOrderForm from "./Form/SellOrderForm";
import SwapOrderForm from "./Form/SwapOrderForm";

const InitiateOrder = () => {
  const [isActive, setIsActive] = useState("Buy");
  let dispatch = useDispatch();
  const { fiatCurrencies, cryptoCurrencies } = useSelector(
    (state) => state.config
  );

  const [getSupportedCurrencies, resultFromSupportedCurrencies] =
    useGetSupportedCurrenciesMutation();

  useEffect(() => {
    getSupportedCurrencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      saveSupportedCurrencies(resultFromSupportedCurrencies?.data?.data)
    );
  }, [dispatch, resultFromSupportedCurrencies?.data?.data]);

  return (
    <div className="lg:px-10 px-6 lg:py-6 py-4">
      <div className="flex items-center gap-12">
        {orderTypes.map((item, i) => (
          <div
            key={i}
            className={`${
              isActive === item
                ? "bg-[#4C9AFF] text-white  px-8 py-1 rounded-2xl"
                : "text-[#7C7C7C]"
            } cursor-pointer text-base mb-12`}
            onClick={() => {
              setIsActive(item);
            }}
          >
            {item}
          </div>
        ))}
      </div>

      {isActive === "Sell" && (
        <SellOrderForm
          fiatCurrencies={fiatCurrencies}
          cryptoCurrencies={cryptoCurrencies}
        />
      )}

      {isActive === "Buy" && (
        <BuyOrderForm
          fiatCurrencies={fiatCurrencies}
          cryptoCurrencies={cryptoCurrencies}
        />
      )}

      {isActive === "Swap" && (
        <SwapOrderForm
          fiatCurrencies={fiatCurrencies}
          cryptoCurrencies={cryptoCurrencies}
        />
      )}
    </div>
  );
};

export default InitiateOrder;
