import React from "react";

const Page404Component = () => {
  return (
    <div className="relative lg:h-[100vh]">
      <div className=" font-mena lg:mb-10 lg:pt-24 pt-3 mb-28">
        <div className="container mx-auto lg:px-[8.3rem] lg:flex  px-5  lg:z-10 ">
          <div className="lg:flex grid w-full items-center lg:gap-10">
            <div className="lg:w-1/2 text-center flex  lg:order-1 order-2 z-10 ">
              <div>
                <h2 className="text-[#0747A6] text-[60px] font-bold">Oops!</h2>
                <p className="text-[#525252] text-[17px] mt-2">
                  It looks like the page you are looking for is not available,
                  while you are here you can
                </p>
                <div className="lg:mt-20 mt-8 lg:flex">
                  <a
                    href="/"
                    className="mr-7 px-6 bg-[#0747A6] text-white py-3 text-center rounded-[42px]  font-space lg:w-1/2 w-full hover:opacity-90 block lg:mb-3 mb-8"
                  >
                    Go to home page{" "}
                  </a>{" "}
                  <a
                    href="/business"
                    className="mr-7 px-6 bg-[#fff] text-[#0747A6] py-3 lg:mb-3 rounded-[42px] text-center font-space lg:w-1/2 w-full  border-[#0747A6] border-[1px] hover:opacity-90 block"
                  >
                    Learn about us
                  </a>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2  flex lg:order-2 order-1 ">
              <img src="images/404.svg" alt="404" />
              {/* <video >
                <source src="images/404.mp4" type="video/mp4" />
                Your browser does not support HTML5 video.
              </video> */}
            </div>
          </div>
        </div>
      </div>
      <div className=" container  mx-auto  lg:px-[8.3rem] lg:mt-[140px] mt-[60px] lg:flex justify-between items-center pb-5 text-[#6A6A6A] text-center lg:text-base text-xs">
        <div className="order-1 lg:mb-0 mb-2 flex justify-center lg:gap-10  lg:order-2">
          <a href="/privacy-policy" className="hover:opacity-80">
            Privacy Policy
          </a>
        </div>
        <p className="lg:mb-0 font-semibold mb-5 lg:order-1 order-2">
          Copyright © {new Date().getFullYear()} XendBridge All Rights Reserved
        </p>
      </div>
      <div className="absolute bottom-0 lg:block hidden">
        <img src="images/blurbg.svg" alt="blurbg" />
      </div>
      <div className="absolute bottom-0 lg:hidden">
        <img src="images/blurbg-sm.svg" alt="blurbg" />
      </div>
    </div>
  );
};

export default Page404Component;
