import React from "react";
import LightSpeed from "react-reveal/LightSpeed";

const SectionOne = () => {
  return (
    <div className="container mx-auto lg:px-0 font-mena lg:pt-40 pt-10 px-5 mb-2 font-mena ">
      <LightSpeed bottom>
        <div className="flex justify-center ">
          <h5 className="text-center text-[#0747A6] lg:text-5xl text-3xl lg:w-[800px] font-bold">
            <span className="text-[#080A0C] text-2xl ">XendBridge</span> OTC Desk
          </h5>
        </div>
        <div className="flex justify-center mt-4 mb-12 lg:mb-20  font-bold">
          <p className="text-center lg:w-[900px] lg:text-5xl  text-base text-[#080A0C]">
            Trade large volumes of crypto easily and at the best rates in the
            industry.
          </p>
        </div>
      </LightSpeed>
      <div className="text-center">
        {" "}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeDQ5VB3F6aoReV3DtXDvRnpePQHulFUHWTpqN9XsmMKt37Ow/viewform"
          className="mr-7 px-6 bg-[#0747A6] text-[#fff] py-3 rounded-[42px]  hover:opacity-90"
          target={"_blank"}
          rel="noreferrer"
        >
          Start Trading
        </a>{" "}
      </div>{" "}
      <div className="flex justify-center lg:mt-0 mt-20">
        <img src="images/B.svg" alt="logo" />
      </div>
    </div>
  );
};

export default SectionOne;
