import React from "react";
import Slide from "react-reveal/Slide";

const SectionSeven = () => {
  return (
    <div className="bg-hero-mask bg-hero-mask-sm   bg-cover bg-center bg-no-repeat  bg-[#F2F8FF] lg:mb-5 lg:p-20 font-mena p-10 h-[1000px] lg:h-auto ">
      <Slide left cascade>
        <div className="container mx-auto lg:px-[8.3rem]">
          {" "}
          <h2 className="lg:text-7xl lg:w-[750px] text-5xl">
            Ready to integrate the XendBridge API?
          </h2>
          <div className="lg:mt-32 lg:flex lg:items-center mt-10 lg:w-[500px]">
            <a
              href="https://integrationssandbox.xendbridge.com/thirdparty/register"
              className="mr-7 px-6 bg-[#0747A6] text-white py-3 text-center rounded-[42px]  font-space hover:opacity-90 block mb-3"
              target="_blank"
              rel="noreferrer"
            >
              Signup Now
            </a>{" "}
          
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default SectionSeven;
