import React from "react";
import { aboutBusiness } from "utils/fx";
import Slide from "react-reveal/Slide";

const SectionTwo = () => {
  return (
    <div className="container mx-auto  px-5 lg:px-[8.3rem] mb-20 mt-40 font-mena ">
      <div className="lg:flex items-end">
        <div className="lg:w-[70%] w-full">
          <div className="lg:inline flex items-end text-[#080A0C] lg:text-5xl text-3xl lg:mb-0 mb-5  font-bold">
            <h6 className="lg:hidden "> Grow your business with XendBridge</h6>
            <div className=" lg:block hidden">
              {" "}
              Grow your business
              <div className="flex items-end ">
                {" "}
                with XendBridge
                {/* <div className="h-[2px] ml-3 w-[20%] mb-6 bg-[#4C9AFF]" /> */}
              </div>
            </div>{" "}
            {/* <div className="h-[2px] lg:hidden  w-[20%] mb-5 bg-[#4C9AFF]" /> */}
          </div>
        </div>
        {/* <div className="lg:w-[35%] w-full flex justify-end">
          <h6 className="text-[#525252] w-[400px] lg:text-lg text-base">
            Our business solution provides you with all the tools you need to
            organize, improve your business payment infrastructure
          </h6>
        </div> */}
      </div>
      <Slide top cascade>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-[3.8rem] mt-28">
          {aboutBusiness.map(({ title, description, image }, i) => (
            <div className="w-full mb-6 lg:text-left text-center" key={i}>
              <div className="lg:inline flex justify-center ">
                {" "}
                <img src={image} alt="transfer" />
              </div>
              <h6 className="lg:text-[20px] text-xl  my-4">{title}</h6>
              <p
                className={`text-base text-[#525252] ${
                  description.includes("chat") && "mt-10"
                }`}
              >
                {description}
              </p>
            </div>
          ))}
        </div>
      </Slide>
      <div className="lg:flex hidden justify-center mt-10">
        <div className="h-[1px] w-[80%] bg-[#DDDDDD]" />
      </div>
    </div>
  );
};

export default SectionTwo;
