import React from 'react';

const Content = () => {
  return (
    <div className="container mx-auto lg:px-[8.3rem] lg:flex lg:py-10 py-10 px-5 relative text-[#080A0C]  ">
      <div className="container  m-0 py-lg-3 py-sm-0 font-[400px]">
        <div className=" mb-5 ">
          <p className="mb-5">
            Welcome to XendBridge, XendBridge is a product created and run by
            Xend Labs Limited (XendFinance). In these Terms and Conditions
            (Terms), the expressions “we”, “us” or “our” shall be used
            interchangeably in reference to XendBridge and its creator.
            XendBridge provides a third party Application Programming Interface
            (API) that when integrated into user's software and/or
            computer/mobile applications, provides the feature of an amazing
            peer-to-peer payment experience for your business. XendBridge is a
            product that offers a peer-to-peer system that allows businesses to
            securely transact between fiat and crypto currency, offering the
            cheapest P2P provider service available.
          </p>
          <p className="mb-2">
            These Terms govern your access and use of XendBridge's website(s),
            our mobile applications, our APIs and any other software, tools,
            apparatus, functionality or features to be provided by us or in
            connection to our services (collectively referred to as “Services”).{' '}
          </p>
          <p className="mb-2">
            These Terms and Conditions must be read alongside our Privacy Policy
            before you proceed to engage with our services.
          </p>
          <p className="mb-5">
            For purposes of interpretation of these Terms, the expression
            “user”, “you” and “your” refers to the user of the services we
            offer, whether as a standalone individual, a group of persons, a
            company or other entity or on behalf of any of the above listed
            entities or groups. Note that if you as a user are using our
            services on behalf of any entity or group, you represent and warrant
            that you are authorized to represent such entity or group and that
            by doing so; you have agreed to these terms and have likewise bound
            such entity or group to these Terms.
          </p>

          <h6 className=" mb-1">General Terms</h6>
          <p className="mb-5">
            These Terms constitute the entire agreement between us and you
            (whether individual, group of persons, corporation or in a
            representative capacity) in relation to your use of our services,
            and it supersedes all prior agreements and understanding if any. So
            please read these Terms carefully as they contain important
            information and shall affect your legal rights. These Terms will be
            in full application and shall at all times manage and guide your use
            of our services. Therefore, by clicking accept or by using our
            services, you have agreed to all the Terms contained here. If you
            disagree with any of the Terms contained here, you must not under
            any circumstances proceed to make use of our services. Minors or
            people below 13 years old are not allowed to use this App.
          </p>

          <h6>Use of Our Service</h6>
          <p className="mb-3">
            XendBridge is a product of Binance and Google Launchpad-backed Xend
            Finance, which offers a peer-to-peer system that allows businesses
            to securely transact between fiat and cryptocurrency. XendBridge
            offers the cheapest P2P provider service available. We provide our
            API to developers who wish to integrate our product in their
            software and/or computer/mobile applications. Our API features
            include:
          </p>
          <ul style={{ listStyle: 'inherit', marginLeft: '40px' }}>
            <li>
              A simplified Buy and Sell feature in our system system. The
              XendBridge system allows users to buy and sell multiple currencies
              in fiat and cryptocurrencies with ease.
            </li>
            <li>
              We offer the cheapest possible cost of transactions for a
              peer-to-peer system, making XendBridge the most affordable for
              your business.
            </li>
            <li>
              Live chat & email support are available 24/7, so you never have to
              worry about unforeseen challenges.
            </li>
            <li>
              By using XendBridge, we offer a dedicated team of experts who work
              with you to integrate our API seamlessly, so you don't have to
              worry about the hassle.
            </li>
          </ul>
          <p className="mb-3">
            By integrating our API, you hereby acknowledge and agree that with
            regards third party transactions on XendBridge, we will cancel buy
            or sell orders if payment was not received from the customer within
            a set period of fifteen minutes to one hour (15mins - 1 hour). This
            cancellation will have a dispute/grace period of another fifteen
            minutes to one hour (15mis - 1hour) within which affected third
            parties must report any technical problem or challenges before we
            will hold that specific transaction closed.
          </p>
          <p className="mb-5">
            Take note that our API is not a wallet, but simply an API designated
            for programmers and software engineers to integrate with their
            software and/or computer/mobile applications to be able to utilize
            our peer to peer system where users can exchange their
            cryptocurrencies with fiat money or vice versa with other users. We
            are not a cryptocurrency exchange, a financial institution or
            creditor.
          </p>

          <h6>Intellectual Property Rights</h6>
          <p className="mb-3">
            By these Terms as provided, we and/or our licensors own all the
            intellectual property rights associated with the contents of our
            service. This includes but is not limited to the texts, graphics,
            images, logos, trademarks, slogans, designs, page headers, notices,
            scripts, icons, information, directives, guidelines, software,
            sounds, codes and all other material that constitutes Intellectual
            Property Rights you may come across while using and/or integrating
            our services/API.
          </p>
          <p className="mb-3">
            By your integration of our API, we make no representation that
            ownership of your interactions or third party interactions with our
            service or the Intellectual Property Rights emanating from such
            interactions have been passed on to you. In fact they remain our
            property and that of our licensors. You are only permitted a limited
            license only for purposes of using our services.
          </p>

          <p className="mb-5">
            Therefore none of the contents of our service may be copied,
            imitated or used, in whole or in part, without prior written
            permission obtained from us and the applicable Intellectual Property
            owners. Note that references to any products, services, processes or
            other information by name, trademark, manufacturer, company,
            supplier or otherwise does not in any way imply or constitute
            sponsorship, partnership, endorsement or recommendation by us or
            vice versa. By integrating our API and/or using our service, you
            hereby grant us a non-exclusive, royalty free, sub-licensable
            license to use, modify and copy any form of interactions you may
            have with our services. You hereby represent and warrant that you
            have the full legal rights and Intellectual Property Rights with
            regards any software and/or computer/mobile application you
            integrate with our API, and any other contents you create, post,
            submit, promote or display through the services we offer. We are
            committed to safeguarding the legal rights and Intellectual Property
            Rights of the public. If you believe that your legal rights or
            intellectual Property Rights is being abused or infringed on, by any
            content created, posted, submitted or being displayed by a user,
            please submit a written notice to a designated agent at:
            Hello@Xendbridge.com
          </p>

          <h6>Restrictions</h6>
          <p>You are specifically restricted from all of the following:</p>
          <ul style={{ listStyle: 'inherit', marginLeft: '40px' }}>
            <li>
              Publishing our API in any other media or host for third party
              access.
            </li>
            <li>
              Publishing any of our services material in any other media without
              any prior written approval from us;
            </li>
            <li>
              Selling, sublicensing and/or otherwise commercializing any of our
              services material without any prior written approval from us;
            </li>
            <li>
              Using our services in any way that is or may be damaging to our
              ecosystem;
            </li>
            <li>
              Using our services in any way that impacts negatively on other
              user’s access/use of our services;
            </li>
            <li>
              Using our service to engage in any form of price manipulation,
              fraud, or any deceptive, misleading, or manipulative activity;
            </li>
            <li>
              Using our services in any way that is unlawful, illegal,
              unauthorized, or in any way that may cause harm to our ecosystem
              or its users;
            </li>
            <li>
              Engaging in any form of data mining, data harvesting, data
              extracting or any other similar activity in relation to our
              services;
            </li>
            <li>
              Using our services in any way that misappropriates any contract,
              intellectual property or other third-party right or the commission
              of tort while using our services.
            </li>
            <li>
              Reverse engineering, decompiling, duplicating, disassembling, or
              decoding any aspect of our API and/or service, or doing anything
              that will discover our source code, circumvent or bypass measures
              put in place to protect our source code or restrict you from
              accessing certain service area;
            </li>
            <li>
              Using our services to engage in any unauthorized form of
              advertising or marketing;
            </li>
            <li>
              Holding or identifying you out as being the proprietor of our API
              and/or services.
            </li>
            <li>
              Use or attempting to use another user’s account without express
              authorization from such user or posing as that user;
            </li>
            <li>
              Disclosing your personal data to other users especially relating
              to your access to our services. Your personal data must remain
              confidential at all times.
            </li>
            <li>
              Encouraging any third party to engage in any activity prohibited
              under these Terms.
            </li>
          </ul>

          <h6 className="mt-5">No Warranties</h6>
          <p className="mb-5">
            We provide our services on an ‘as is’ and ‘as available’ basis
            without any promises or representations, express or implied. In
            particular, we do not warrant or make any representation regarding
            the validity, accuracy, reliability or availability of services we
            offer. All user information is based on the information provided by
            users that sign up for our services for the purpose of integration
            of our API. Take note that any information or contents you come
            across in our services may be out of date or varied and we make no
            commitment to update it. To the fullest extent permitted by
            applicable laws, we hereby exclude all promises, whether express or
            implied, including any promises that our services are fit for
            purpose, of satisfactory quality, non-infringing, is free of
            defects, is able to operate on an uninterrupted basis, that the use
            of our services by you is in compliance with laws or that any
            information that you transmit in connection with our services will
            be successfully, accurately or securely transmitted. Note that it is
            the responsibility of the user at all times to ensure that our
            services are permitted by the laws, rules and regulations applicable
            to the users geography and/ or locality before proceeding to use our
            services.
          </p>

          <h6>Limitation of Liability</h6>
          <p className="mb-5">
            To the fullest extent permitted under applicable laws, in no event
            shall we be liable to you with respect to your use of our services
            and/or be liable to you for any direct, indirect, special or
            consequential damages, including without limitation to damages for
            loss of goodwill, loss of profit, theft or corruption of your
            information, the inability to use our services and any connected
            device failure or malfunction thereof. We shall not be liable even
            if it has been advised of the possibility of such damages, including
            without limitation to damages caused by error, omission,
            interruption, defect, failure of performance, unauthorized use,
            delay in operation or transmission, line failure, computer virus,
            worm, Trojan horse or other harm. In the event that any applicable
            law does not allow the exclusion of certain promises and/or the
            exclusion of liability for direct, indirect, consequential or other
            damages, then such liability arising under or in connection with
            these Terms and your use of our services shall not exceed $50.
          </p>

          <h6>Indemnification</h6>
          <p className="mb-5">
            You hereby indemnify us to the fullest extent from and against any
            and/or all liabilities, costs, demands, causes of action, damages
            and expenses arising out of or in any way related to your breach of
            any of the provisions of these Terms and your use of our services.
          </p>

          <h6>Severability</h6>
          <p className="mb-5">
            If any provision of these Terms is found to be invalid under any
            applicable law, such provisions alone shall not be applicable in
            these present and shall not affect or invalidate the remaining
            provisions contained here.
          </p>

          <h6>Variation of Terms</h6>
          <p className="mb-5">
            We are permitted to revise these Terms at any time as we see fit,
            and by using our services, you are expected to review these Terms on
            a regular basis so as to track such changes.
          </p>

          <h6>Assignment</h6>
          <p className="mb-5">
            We are at liberty to assign, transfer, and subcontract our rights
            and/or obligations, in whole or in part, under these Terms without
            any prior notification to you. However, you are not allowed to
            assign, transfer, or subcontract any or all of your rights and/or
            obligations under these Terms, except with our requisite approval.
          </p>

          <h6>Entire Agreement</h6>
          <p className="mb-5">
            These Terms constitute the entire agreement between us and you in
            relation to your use of our services, and it supersedes all prior
            agreements and understanding if any.
          </p>

          <h6> Governing Laws & Jurisdiction</h6>
          <p className="mb-5">
            These Terms are governed by and interpreted in accordance with the
            laws and regulations of the British Virgin Islands.
          </p>

          <h6> Disputes Resolution/Arbitration </h6>
          <p className="mb-5">
            In the event of any dispute, controversy or claim arising out of or
            in relation to these Terms or your use of our services, such
            dispute, controversy or claim shall be settled or resolved in
            accordance with the provisions of the Arbitration Act, 2003 as in
            force in The British Virgin Islands or in accordance with the
            procedures of the British Virgin Islands International Arbitration
            Center. With respect to all persons and entities, regardless of
            whether they have obtained or used our services for personal,
            commercial or other purposes, all disputes, controversies or claims
            must be brought in the parties’ individual capacity, and not as a
            plaintiff or class member in any purported class action, collective
            action or other representative proceeding. This waiver applies to
            class arbitration, and, unless we agree otherwise, the arbitrator
            may not consolidate more than one claim. You agree that, by entering
            into this agreement, you and we are each waiving the right to a
            trial by any court of competent jurisdiction. Any dispute,
            controversy or claim arising out of or relating to this contract, or
            the breach, termination or invalidity thereof, shall be settled by
            arbitration in accordance with the BVI IAC Arbitration Rules.
          </p>
          <h6>About Us</h6>
          <p className="mb-5">
            Xend Labs Limited (XendFinance) is a company incorporated in the
            British Virgin Islands (BVI), with BVI Company Number: 2046053 and
            its registered office address situate at Vistra Corporate Services
            Centre, Wickhams Cay 11, Road Town, Tortola, VG1110, British Virgin
            Islands.
          </p>

          <h6 className="">Contact us</h6>
          <p className="mb-3">
            If there are any questions regarding this terms and conditions, you
            may contact us using the information below.
          </p>
          <p className="mb-3">Hello@Xendbridge.com</p>
        </div>
      </div>
    </div>
  );
};

export default Content;
