import React from "react";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import CountUp from "react-countup";
import Lottie from "react-lottie";
import animationData from '../../assets/headerart.json'

const SectionOne = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
      {" "}
      <div className="container  mx-auto lg:flex  lg:pt-10 pt-3 font-mena ">
        <div className="lg:w-[47%] pb-7 lg:pt-[8rem]  pt-5 px-5 lg:pl-[8.3rem] lg:px-0">
          <h4 className="lg:text-[35.94px] text-[34px] font-bold lg:leading-[50px] leading-[40px] mb-10">
            A Decentralized Crypto Payment API for Your Business
          </h4>
          <p className="mb-4 text-[#7C7C7C] lg:text-[19px] lg:pr-8 text-[18px] leading-[30px]">
            Harness the power of our superfast payment API to reach more
            customers. Provide on-ramp and off-ramp services to your users at
            zero fees.
          </p>
          <div className="mt-8 lg:flex">
            <a
              href="https://integrationssandbox.xendbridge.com/thirdparty/register"
              className="mr-7 px-6 bg-[#0747A6] text-white py-3 text-center rounded-[42px]  font-space lg:w-1/2 w-full hover:opacity-90 block mb-3"
              target={"_blank"}
              rel="noreferrer"
            >
              Get Started
            </a>{" "}
            <a
              href="https://lite.xendbridge.com/"
              className="mr-7 px-6 bg-[#4C9AFF] text-white py-3 lg:mb-3 rounded-[42px] text-center font-space lg:w-1/2 w-full hover:opacity-90 block"
              target={"_blank"}
              rel="noreferrer"
            >
              Trade Now
            </a>
          </div>
        </div>
        <div className="lg:w-[53%] relative pb-7 lg:pt-[8rem] lg:pr-[8.3rem] lg:overflow-visible overflow-hidden">
          <Fade right>
            {/* <img
              src="images/Header-art.png"
              className="relative w-full h-[400px]"
              style={{ objectFit: "cover" }}
              alt="popup"
            /> */}
            <Lottie options={defaultOptions} height={400} width={400} style={{ objectFit: "cover" }} />
          </Fade>
        </div>
      </div>
      <Zoom>
        <div className="container mx-auto mt-10 lg:mb-20 mb-10 font-mena lg:px-[8.3rem] ">
          <div className="lg:border-l-4 border-[#0747A6] lg:border-t-0 border-t-4 lg:mx-0 mx-5 flex-wrap py-10 lg:px-20 px-5 flex items-center lg:justify-around justify-between  bg-[#F8F8F8]">
            <div className="lg:py-0 py-4 order-1">
              <h3 className="text-[#0747A6] lg:text-4xl text-3xl ">
                <CountUp end={7377} separator="," />+
              </h3>
              <span className="text-[#525252] lg:text-sm text-xs">
                Total No of Transactions
              </span>
            </div>
            <div className="lg:py-0  py-4 lg:order-2 order-3">
              <h3 className="text-[#0747A6] lg:text-4xl text-3xl">
                $<CountUp end={15} separator="" />
                .74m+
              </h3>
              <span className="text-[#525252] lg:text-sm text-xs">
                Total Buy & Sell Orders
              </span>
            </div>
            <div className="lg:py-0  py-4 lg:order-3 order-2">
              <h3 className="text-[#0747A6] lg:text-4xl text-3xl lg:pr-0 pr-10">
                <CountUp end={200} separator="," />
                k+
              </h3>
              <span className="text-[#525252] lg:text-sm text-xs">
                XendBridge Users
              </span>
            </div>
            <div className=" lg:py-0 py-4 order-4">
              <h3 className="text-[#0747A6] lg:text-4xl text-3xl">
                <CountUp end={40} separator="," />+
              </h3>
              <span className="text-[#525252] lg:text-sm text-xs">
                Supported Currencies
              </span>
            </div>
          </div>
        </div>
      </Zoom>
    </>
  );
};

export default SectionOne;
