import React from "react";
import Slide from "react-reveal/Slide";

const SectionThree = () => {
  return (
    <div className="container mx-auto lg:px-[8.3rem] px-5 mb-36 lg:mt-28 font-mena ">
      <div className=" lg:flex">
        {" "}
        <Slide left cascade>
          <div className="lg:w-1/2">
            <h4 className="text-[#080A0C] lg:text-5xl text-4xl lg:w-[500px] mb-8">
              A business-friendly dashboard.{" "}
            </h4>
            <p className="text-[#525252] text-xl lg:w-[400px]">
              Designed and built to handle all your P2P transactions, appeals,
              and settlements efficiently.
            </p>
            <div className="mt-8  mt-10 lg:w-[500px]">
              <a
                href="https://integrationssandbox.xendbridge.com/thirdparty/register"
                className="mr-7 h-[42px] w-[172px] bg-[#0747A6] text-white py-2 text-center rounded-[42px]  font-space hover:opacity-90 block lg:mb-3 mb-10"
                target="_blank"
                rel="noreferrer"
              >
                Signup
              </a>{" "}
            </div>
          </div>
        </Slide>
        <div className="lg:w-1/2 w-full">
          <img src="images/dashboard2.svg" alt="dashboard" />
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
