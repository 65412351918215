import React, { useState } from "react";
import Fade from "react-reveal/Fade";
// import Zoom from "react-reveal/Zoom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const Trading = () => {
  const [tradingColor, setTradingColor] = useState("#FFAB19");
  const [tradingTrailColor, setTradingTrailColor] = useState("#ffab1930");
  const [tradingIndex, setTradingIndex] = useState(1);

  const changeTradeBanner = (remainingTime) => {
    if (tradingIndex === 1) {
      setTradingColor("#FFAB19");
      setTradingTrailColor("#ffab1930");
    } else if (tradingIndex === 2) {
      setTradingColor("#0747A6");
      setTradingTrailColor("#0747a669");
    } else if (tradingIndex === 3) {
      setTradingColor("#32C5A7");
      setTradingTrailColor("#32c5a74d");
    }

    if (remainingTime === 0) {
      tradingIndex < 3 ? setTradingIndex(tradingIndex + 1) : setTradingIndex(1);
    }
  };
  return (
    <div className="2xl:px-[18rem] lg:px-[13rem] font-mena">
      {" "}
      <div
        className={`container lg:px-16 lg:py-10 p-5  lg:pt-8  mx-auto  mb-10 lg:mb-40 font-mena  text-white rounded-lg ${
          tradingIndex === 1 &&
          `bg-hero-p2p bg-cover bg-center bg-no-repeat  bg-[#080A0C] `
        } ${
          tradingIndex === 2 &&
          " bg-hero-otc bg-cover bg-center bg-no-repeat  bg-[#F2F8FF]"
        } ${
          tradingIndex === 3 &&
          "bg-hero-api bg-cover bg-center bg-no-repeat  bg-[#F2F8FF]"
        } `}
      >
        <div className="w-full">
          {" "}
          <div className="flex justify-between ">
            <div className="flex lg:gap-10 gap-3 flex-wrap">
              <div
                className={`cursor-pointer bg-[#fff] text-black py-2 lg:px-10 px-5 rounded-[42px] hover:opacity-90 ${
                  tradingIndex === 1 && "bg-[#FFF3DF] text-[#FFAB19]"
                }`}
                onClick={() => {
                  setTradingIndex(1);
                }}
              >
                P2P Trading
              </div>
              <div
                className={`cursor-pointer bg-[#fff] text-black py-2 lg:px-10 px-5 rounded-[42px] hover:opacity-90 ${
                  tradingIndex === 2 && "bg-[#CBE3FF] text-[#0747A6]"
                }`}
                onClick={() => {
                  setTradingIndex(2);
                }}
              >
                OTC Trading{" "}
              </div>
              <div
                className={`cursor-pointer bg-[#fff] text-black py-2 lg:px-10 px-5 rounded-[42px] hover:opacity-90 ${
                  tradingIndex === 3 && "bg-[#ffffff3b] text-[#32C5A7]"
                }`}
                onClick={() => {
                  setTradingIndex(3);
                }}
              >
                API integration
              </div>
            </div>
            <div>
              <CountdownCircleTimer
                isPlaying
                duration={10}
                colors={[tradingColor]}
                colorsTime={[10]}
                onComplete={() => ({ shouldRepeat: true, delay: 0 })}
                size={35}
                strokeWidth={3}
                onUpdate={(remainingTime) => changeTradeBanner(remainingTime)}
                trailColor={tradingTrailColor}
              >
                {({ remainingTime }) => (
                  <div className="flex items-center justify-center font-mena">
                    <div style={{ color: tradingColor }} className="text-xs">
                      {remainingTime}s
                    </div>
                  </div>
                )}
              </CountdownCircleTimer>
            </div>
          </div>
        </div>
        <Fade bottom cascade>
          <div className="lg:flex lg:mt-16 mt-8" id="partners">
            <div className="lg:w-4/6 w-full ">
              <div className="lg:w-[540px]">
                <h4
                  className={`font-bold lg:text-4xl text-3xl lg:mb-10 mb-5 ${
                    tradingIndex === 1 && "text-white"
                  } ${tradingIndex === 2 && "text-[#0747A6]"} ${
                    tradingIndex === 3 && "text-[#1E8570]"
                  }`}
                >
                  {tradingIndex === 1 && (
                    <Fade bottom>
                      Fast and Secured P2P Trading with Xendbridge Lite
                    </Fade>
                  )}
                  {tradingIndex === 2 && (
                    <Fade bottom>Trade OTC with XendBridge</Fade>
                  )}
                  {tradingIndex === 3 && (
                    <Fade bottom>Integrate Our P2P Payment Solution.</Fade>
                  )}
                </h4>
                {tradingIndex === 1 && (
                  <Fade bottom>
                    <p
                      className={`lg:w-[450px] lg:text-2xl text-sm mb-8 ${
                        tradingIndex === 1 ? "text-white" : "text-black"
                      }`}
                    >
                      Join thousands of satisfied customers across the globe to
                      trade crypto with XendBridge Lite.
                    </p>
                  </Fade>
                )}
                {tradingIndex === 2 && (
                  <Fade bottom>
                    <p
                      className={`lg:w-[450px] lg:text-2xl text-sm mb-8 ${
                        tradingIndex === 1 ? "text-white" : "text-black"
                      }`}
                    >
                      Execute large OTC orders privately. Our personalized
                      premium OTC desk trading is secure and fast.
                    </p>
                  </Fade>
                )}
                {tradingIndex === 3 && (
                  <Fade bottom>
                    <p
                      className={`lg:w-[450px] lg:text-2xl text-sm mb-8 ${
                        tradingIndex === 1 ? "text-white" : "text-black"
                      }`}
                    >
                      Our decentralized cross-boarder P2P payment solutions
                      gives your users ability to transact faster and securely.
                    </p>
                  </Fade>
                )}

                <div className="flex items-center">
                  {" "}
                  {(tradingIndex === 1 || tradingIndex === 2) && (
                    <a
                      href="https://lite.xendbridge.com/"
                      className={`mr-7 lg:px-6 px-4 text-white py-1 rounded-[25px]  hover:opacity-90  items-center lg:w-[183px] w-[175px] flex text-sm ${
                        tradingIndex === 1 && " bg-[#FFAB19]"
                      } ${tradingIndex === 2 && " bg-[#0747A6]"} ${
                        tradingIndex === 3 && " bg-[#32C5A7]"
                      }`}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Start Trading&nbsp;&nbsp;
                      <img src="images/right-arrow.svg" alt="arrow" />
                    </a>
                  )}
                  {tradingIndex === 3 && (
                    <a
                      href="https://doc.xendbridge.com/"
                      className={`mr-7 lg:px-6 px-4 text-white py-1 rounded-[25px]  hover:opacity-90  items-center lg:w-[240px] w-[230px] flex text-sm ${
                        tradingIndex === 1 && " bg-[#FFAB19]"
                      } ${tradingIndex === 2 && " bg-[#0747A6]"} ${
                        tradingIndex === 3 && " bg-[#32C5A7]"
                      }`}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      See Documentation &nbsp;&nbsp;
                      <img src="images/right-arrow.svg" alt="arrow" />
                    </a>
                  )}
                  {tradingIndex === 1 && (
                    <a
                      href="/individual"
                      className={`${
                        tradingIndex === 1 ? "text-white" : "text-black"
                      } `}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Learn more
                    </a>
                  )}{" "}
                  {tradingIndex === 2 && (
                    <a
                      href="/otc-desk"
                      className={`${
                        tradingIndex === 1 ? "text-white" : "text-black"
                      } `}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Learn more
                    </a>
                  )}
                  {tradingIndex === 3 && (
                    <a
                      href="/business"
                      className={`${
                        tradingIndex === 1 ? "text-white" : "text-black"
                      } `}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Learn more
                    </a>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="lg:w-2/6 w-full mt-20 lg-mt-0">
              {tradingIndex === 1 && (
                <Zoom>
                  <img src="images/image.svg" alt="union" />
                </Zoom>
              )}
              {tradingIndex === 2 && (
                <Zoom>
                  <img src="images/image.svg" alt="union" />
                </Zoom>
              )}
              {tradingIndex === 3 && (
                <Zoom>
                  <img src="images/image.svg" alt="union" />
                </Zoom>
              )}
            </div> */}
          </div>
        </Fade>
      </div>
    </div>
  );
};
export default Trading;
