import React from "react";
import CountUp from "react-countup";
import InitiateOrder from "./InitiateOrder";

const SectionOne = () => {
  return (
    <div className="bg-hero-individual font-mena lg:mb-10 lg:pt-24 pt-3 mb-32">
      <div className="container mx-auto lg:px-[8.3rem] lg:flex lg:py-20 px-5 relative ">
        <div className="lg:w-[60%] lg:pt-8">
          <h2 className="lg:text-5xl text-4xl lg:w-[450px] leading-12 font-bold">
            Buy and sell your crypto and fiat currencies in just a few clicks
          </h2>
          <div className="text-[#7C7C7C] lg:mt-8 mt-2">
            FAST, SECURED, SEAMLESS TRADING
          </div>

          <div className="flex text-[#7C7C7C] lg:mt-24 mt-3 relative">
            <p className="lg:mt-5 font-bold">
              Join over <CountUp end={200} />
              k+
              <br /> happy users
            </p>
            <div className="absolute  arrowLineBox ">
              <img
                src="images/arrow-line.svg"
                alt="arrow"
                className="arrowLine"
              />
            </div>
          </div>
        </div>
        <div className="lg:w-[40%] lg:mt-0 mt-5 flex items-center lg:justify-end justify-center  ">
          <div className=" cardBoxShadow rounded lg:w-[420px] w-[340px] bg-white">
            <InitiateOrder />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
