import React from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Content from 'components/TermsAndCondition/Content';
import SectionOne from 'components/TermsAndCondition/SectionOne';

const TermsAndCondition = () => {
  return (
    <>
      <Header />
      <SectionOne />
      <Content />
      <Footer />
    </>
  );
};

export default TermsAndCondition;
