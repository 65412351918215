import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  supportedCurriences: [],
  cryptoCurrencies: [],
  fiatCurrencies: [],
};

const config = createSlice({
  name: "config",
  initialState,
  reducers: {
    saveSupportedCurrencies: (state, action) => {
      state.supportedCurriences = action.payload;
      let crypto = action?.payload?.filter(
        (item) => item?.currencyType === "CRYPTO" && item
      );
      state.cryptoCurrencies = crypto;

      let fiat = action?.payload?.filter(
        (item) => item?.currencyType === "FIAT" && item
      );
      state.fiatCurrencies = fiat;
    },
  },
});

export const { saveSupportedCurrencies } = config.actions;
export default config.reducer;
