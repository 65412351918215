import React from "react";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

const SectionTwo = () => {
  return (
    <div className="container mx-auto mt-[12rem] lg:mt-[20rem] lg:mb-20 lg:px-[8.3rem] px-5 font-mena lg:flex lg:gap-6">
      <Fade left cascade>
        <div className="lg:w-1/3 lg:mb-0 mb-10">
          <h4 className="lg:text-5xl text-5xl font-bold">
            So, how does it work?
          </h4>
          <p className="text-xl text-[#3B3B3B]">
            No rigorous verification process*
          </p>
        </div>{" "}
      </Fade>
      <Zoom top cascade>
        <div className="lg:w-2/3">
          <div className="lg:flex">
            <div className="lg:w-1/2 lg:mb-20 lg:px-6 mb-8">
              <img src="images/login.svg" alt="icon" />
              <h6 className="text-xl mt-3 lg:mb-2">Enter the amount</h6>
              <p className="text-lg text-[#525252]">
                Enter the amout of crypto you want to buy, sell or swap.
              </p>
            </div>
            <div className="w-1/2"></div>
          </div>
          <div className="lg:flex">
            <div className="lg:w-1/2 lg:mb-10 lg:px-8 mb-8">
              <img src="images/placeorder.svg" alt="icon" />
              <h6 className="text-xl mt-3 lg:mb-2">Place your order</h6>
              <p className="text-lg text-[#525252]">
                Place and confirm your order.
              </p>
            </div>
            <div className="lg:w-1/2 lg:mb-10 lg:px-8 mb-8">
              <img src="images/smartphone.svg" alt="icon" />
              <h6 className="text-xl mt-3 lg:mb-2">Receive payment</h6>
              <p className="text-lg text-[#525252]">
                Receive your crypto/fiat in few minutes. It is that simple!
              </p>
            </div>{" "}
          </div>
        </div>
      </Zoom>
    </div>
  );
};

export default SectionTwo;
