import React from "react";
// import LightSpeed from "react-reveal/LightSpeed";
import Fade from "react-reveal/Fade";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Bounce from "react-reveal/Bounce";

const SectionOne = () => {
  return (
    <div className=" bg-hero-business">
      <div className="container mx-auto lg:px-0 lg:pt-40  pt-10 px-5 mb-20 font-mena ">
        <>
          <Bounce bottom>
            <div className="flex justify-center ">
              <h5 className="text-center text-white lg:text-5xl text-3xl lg:w-[800px] font-bold">
                Supercharge your business with{" "}
                <span className="text-[#4C9AFF]">XendBridge</span> payment
                infrastructure
              </h5>
            </div>
            <div className="flex justify-center mt-6  ">
              <p className="text-center lg:w-[450px] text-lg mb-4 text-white">
                Onramp and offramp crypto and fiat seamlessly.
              </p>{" "}
            </div>

            <div className="flex justify-center mb-8  ">
              <img src="images/currencies.svg" alt="" />
            </div>
          </Bounce>{" "}
          {/* <div className=" lg:flex mx-auto justify-center lg:mt-20 mt-40 lg:w-[500px] mb-[400px]">
            <a
              href="https://integrationssandbox.xendbridge.com/thirdparty/register"
              className="mr-7 px-6 bg-[#fff] text-[#0747A6] py-3 text-center rounded-[42px]  font-space lg:w-2/3 w-full hover:opacity-90 block lg:mb-3 mb-4 font-bold"
              target="_blank"
              rel="noreferrer"
            >
              To get started - Signup
            </a>{" "}
            <a
              href="https://integrationssandbox.xendbridge.com/thirdparty/"
              className="mr-7 px-6 bg-[#4C9AFF] text-white py-3 lg:mb-3 mb-16 rounded-[42px] text-center font-space lg:w-1/2 w-full hover:opacity-90 block font-bold"
              target="_blank"
              rel="noreferrer"
            >
              Sign in
            </a>
          </div> */}
          <div className="relative lg:flex justify-center ">
            <Bounce bottom cascade>
              <img src="images/hero-dashboard.svg" alt="" />
            </Bounce>
            {/* <div className="lg:absolute lg:block hidden lg:top-[15%] lg:right-0">
              <img src="images/popup.svg" alt="" />
            </div> */}
            {/* <div className="lg:hidden -mt-16">
              <img src="images/popup-sm.svg" alt="" />
            </div> */}
            <div className="lg:absolute top-[60%]  z-10 relative lg:left-[30%] lg:flex lg:items-center lg:mt-10 -mt-10 lg:w-[500px]">
              <a
                href="https://integrationssandbox.xendbridge.com/thirdparty/register"
                className="mr-7 px-6 bg-[#fff] text-[#0747A6] py-3 text-center rounded-[42px]  font-space lg:w-2/3 w-full hover:opacity-90 block lg:mb-3 mb-10 font-bold"
                target="_blank"
                rel="noreferrer"
              >
                To get started - Signup
              </a>{" "}
              <a
                href="https://integrationssandbox.xendbridge.com/thirdparty/"
                className="mr-7 px-6 bg-[#4C9AFF] text-white py-3 lg:mb-3 mb-16 rounded-[42px] text-center font-space lg:w-1/2 w-full hover:opacity-90 block font-bold"
                target="_blank"
                rel="noreferrer"
              >
                Sign in
              </a>
            </div>
          </div>
        </>
        <>
          <div className="lg:px-[8.3rem] mt-10 text-white mb-10">
            <h4 className="text-4xl lg:w-[600px] mb-5">
              Your goal to reach more customers starts here.{" "}
            </h4>
            <p className="text-xl lg:w-[400px] ">
              Be a part of the decentralized payment revolution.
            </p>
          </div>{" "}
          <Fade right cascade>
            <div className="lg:px-20 mt-10 lg:pb-20 pb-10 lg:flex-row flex flex-col lg:justify-between text-white">
              <div className="lg:w-[250px] w-[220px] mb-10 mx-auto">
                <h4 className="lg:text-8xl text-6xl  mb-1 text-[#4C9AFF]">
                  <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                    {({ isVisible }) => (
                      <div className="lg:h-[100px] h-[60px]">
                        {isVisible ? (
                          <>
                            <CountUp end={300} separator="," delay={0} />+
                          </>
                        ) : null}
                      </div>
                    )}
                  </VisibilitySensor>
                </h4>
                <p className="lg:text-xl text-base  ">
                  million global crypto currency Users{" "}
                </p>
              </div>{" "}
              <div className="lg:w-[320px] w-[220px] mb-10  mx-auto">
                <h4 className="lg:text-8xl text-6xl  mb-1 text-[#4C9AFF]">
                  <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                    {({ isVisible }) => (
                      <div className="lg:h-[100px] h-[60px]">
                        {isVisible ? (
                          <>
                            <CountUp end={1800} separator="," delay={0} />+
                          </>
                        ) : null}
                      </div>
                    )}
                  </VisibilitySensor>
                </h4>
                <p className="lg:text-xl text-base  ">
                  business are already accepting crypto currency worldwide{" "}
                </p>
              </div>{" "}
              <div className="lg:w-[250px] w-[220px] mb-10  mx-auto">
                <h4 className="lg:text-8xl text-6xl  mb-1 text-[#4C9AFF]">
                  <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                    {({ isVisible }) => (
                      <div className="lg:h-[100px] h-[60px]">
                        {isVisible ? (
                          <>
                            <CountUp end={90} />
                            %+
                          </>
                        ) : null}
                      </div>
                    )}
                  </VisibilitySensor>
                </h4>
                <p className="lg:text-xl text-base  ">of global GDP</p>
              </div>
            </div>{" "}
          </Fade>
        </>

        <div className="flex lg:justify-end justify-center text-[#4C9AFF] lg:pb-12 pb-28">
          Data source: Bitcoinworld
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
