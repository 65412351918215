import { useOrder } from "hooks";
import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import ErrorMessage from "../Input/ErrorMessage";
import PayInInput from "../Input/PayIn";
import Rate from "../Input/Rate";
import ReceiveInInput from "../Input/ReceiveIn";

const SwapOrderForm = ({ fiatCurrencies, cryptoCurrencies }) => {
  const { getBestRate, handleSwapOrderRate } = useOrder();

  const [activeCurrencyType, setActiveCurrencyType] = useState("fiat");

  const [minAmountErrorMessage, setMinAmountErrorMessage] = useState(false);
  const [maxAmountErrorMessage, setMaxAmountErrorMessage] = useState(false);
  const [minAmount, setMinAmount] = useState();
  const [maxAmount, setMaxAmount] = useState();
  const [startingRate, setStartingRate] = useState();
  const [payInCurrency, setPayInCurrency] = useState();
  const [receiveInCurrency, setReceiveInCurrency] = useState();
  const [receiveInNetwork, setReceiveInNetwork] = useState();
  const [payInNetwork, setPayInNetwork] = useState();
  const [payInValue, setPayInValue] = useState("");
  const [receiveInValue, setReceiveInValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rateError, setRateError] = useState();
  const [bestRate, setBestRate] = useState();
  const [receiveInCurrencyImg, setReceiveInCurrencyImg] = useState();
  const [payInCurrencyImg, setPayInCurrencyImg] = useState();

  let supportedCurrencies =
    activeCurrencyType === "fiat" ? fiatCurrencies : cryptoCurrencies;

  useEffect(() => {
    getBestRate(
      null,
      null,
      null,
      null,
      "swap",
      payInCurrency || supportedCurrencies?.[0]?.currencySymbol,
      payInNetwork || supportedCurrencies?.[0]?.network,
      receiveInCurrency || supportedCurrencies?.[1]?.currencySymbol,
      receiveInNetwork || supportedCurrencies?.[1]?.network,
      setBestRate,
      setStartingRate,
      setRateError
    );
    const intervalCall = setInterval(() => {
      getBestRate(
        null,
        null,
        null,
        null,
        "swap",
        payInCurrency || supportedCurrencies?.[0]?.currencySymbol,
        payInNetwork || supportedCurrencies?.[0]?.network,
        receiveInCurrency || supportedCurrencies?.[1]?.currencySymbol,
        receiveInNetwork || supportedCurrencies?.[1]?.network,
        setBestRate,
        setStartingRate,
        setRateError
      );
    }, 20000); //20 seconds
    return () => {
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    receiveInCurrency,
    receiveInNetwork,
    payInCurrency,
    payInNetwork,
    fiatCurrencies,
    cryptoCurrencies,
  ]);

  const submitHandler = async () => {
    setIsLoading(true);
    window.location.href = "https://lite.xendbridge.com/";
  };

  const resetForm = (type) => {
    let supportedCurrencies_ =
      type === "fiat" ? fiatCurrencies : cryptoCurrencies;

    setMaxAmountErrorMessage();
    setMinAmountErrorMessage();
    setRateError();
    setPayInValue("");
    setReceiveInValue("");

    setPayInCurrency(supportedCurrencies_?.[0]?.currencySymbol);
    setPayInNetwork(supportedCurrencies_?.[0]?.network);
    setPayInCurrencyImg(supportedCurrencies_?.[0]?.symbolUrl);
    setReceiveInCurrency(supportedCurrencies_?.[1]?.currencySymbol);
    setReceiveInNetwork(supportedCurrencies_?.[1]?.network);
    setReceiveInCurrencyImg(supportedCurrencies_?.[1]?.symbolUrl);
  };

  return (
    <div className="font-mena">
      <div className="flex items-center justify-between">
        <Label>You Pay In</Label>
        <form className="flex gap-6">
          <div>
            <input
              checked={activeCurrencyType === "fiat" && "checked"}
              type="radio"
              name="option"
              id="fiat"
              onChange={() => {
                setActiveCurrencyType("fiat");
                resetForm("fiat");
              }}
            />
            <label
              htmlFor="fiat"
              className="text-[#7C7C7C] label-checked:text-[#0747A6] ml-1 cursor-pointer"
            >
              Fiat
            </label>
          </div>
          <div>
            <input
              type="radio"
              name="option"
              id="crypto"
              onChange={() => {
                setActiveCurrencyType("crypto");
                resetForm("crypto");
              }}
              checked={activeCurrencyType === "crypto" && "checked"}
            />
            <label
              htmlFor="crypto"
              className="text-[#7C7C7C] cursor-pointer label-checked:text-[#0747A6] ml-1"
            >
              Crypto
            </label>
          </div>
        </form>
      </div>

      <PayInInput
        setMaxAmount={setMaxAmount}
        setMinAmount={setMinAmount}
        payInNetwork={payInNetwork || supportedCurrencies?.[0]?.network}
        receiveInCurrency={
          receiveInCurrency || supportedCurrencies?.[1]?.currencySymbol
        }
        receiveInNetwork={supportedCurrencies?.[1]?.network}
        payInCurrency={
          payInCurrency || supportedCurrencies?.[0]?.currencySymbol
        }
        setPayInCurrency={setPayInCurrency}
        supportedCurrencies={supportedCurrencies}
        handleSwapOrderRate={handleSwapOrderRate}
        setMinAmountErrorMessage={setMinAmountErrorMessage}
        setMaxAmountErrorMessage={setMaxAmountErrorMessage}
        payInValue={payInValue}
        maxAmount={maxAmount}
        receiveInValue={receiveInValue}
        setPayInValue={setPayInValue}
        setReceiveInValue={setReceiveInValue}
        setPayInNetwork={setPayInNetwork}
        payInCurrencyImg={
          payInCurrencyImg || supportedCurrencies?.[0]?.symbolUrl
        }
        setPayInCurrencyImg={setPayInCurrencyImg}
        minAmount={minAmount}
      />

      <Label>You Receive</Label>
      <ReceiveInInput
        minAmount={minAmount}
        payInCurrency={
          payInCurrency || supportedCurrencies?.[0]?.currencySymbol
        }
        setMaxAmount={setMaxAmount}
        setMinAmount={setMinAmount}
        payInNetwork={payInNetwork || supportedCurrencies?.[0]?.network}
        receiveInNetwork={supportedCurrencies?.[1]?.network}
        receiveInCurrency={
          receiveInCurrency || supportedCurrencies?.[1]?.currencySymbol
        }
        supportedCurrencies={supportedCurrencies}
        handleSwapOrderRate={handleSwapOrderRate}
        setMinAmountErrorMessage={setMinAmountErrorMessage}
        setMaxAmountErrorMessage={setMaxAmountErrorMessage}
        receiveInValue={receiveInValue}
        maxAmount={maxAmount}
        setPayInValue={setPayInValue}
        setReceiveInValue={setReceiveInValue}
        setReceiveInNetwork={setReceiveInNetwork}
        receiveInCurrencyImg={
          receiveInCurrencyImg || supportedCurrencies?.[1]?.symbolUrl
        }
        setReceiveInCurrencyImg={setReceiveInCurrencyImg}
        setReceiveInCurrency={setReceiveInCurrency}
      />

      <ErrorMessage
        minAmountErrorMessage={minAmountErrorMessage}
        maxAmountErrorMessage={maxAmountErrorMessage}
        maxAmount={maxAmount}
        rateError={rateError}
        minAmount={minAmount}
      />
      <Rate
        startingRate={startingRate}
        fiatCurrency={payInCurrency || supportedCurrencies?.[0]?.currencySymbol}
        cryptoCurrency={
          receiveInCurrency || supportedCurrencies?.[1]?.currencySymbol
        }
        bestRate={bestRate}
      />
      <div className="grid mt-10 gap-2">
        <button
          className="bg-[#0747A6] text-white rounded-[40px] py-3 btn-style disabled:opacity-80"
          disabled={
            !payInValue ||
            !receiveInValue ||
            receiveInValue < minAmount ||
            receiveInValue > maxAmount
          }
          onClick={submitHandler}
        >
          {isLoading ? (
            <i className="fa fa-spinner fa-pulse mx-2 " aria-hidden="true"></i>
          ) : (
            "Swap"
          )}
        </button>
      </div>
    </div>
  );
};

const Label = tw.label`text-[#7C7C7C] lg:text-lg text-base`;

export default SwapOrderForm;
