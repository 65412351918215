import { configureStore } from "@reduxjs/toolkit";

import { api } from "services/index";
import configReducer from "./slice/config/config.slice";

const reducer = {
  config: configReducer,
  [api.reducerPath]: api.reducer,
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
