import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import BackDrop from './BackDrop';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

const FinancialReportModal = ({ setOpenModal }) => {
  const form = useRef();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState('');

  const handleClose = () => {
    setOpenModal(false);
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSignUp = () => {
    if (isValidEmail(email)) {
      // send request
      setIsLoading(true);
      emailjs
        .sendForm(
          'service_o8ffo5b',
          'template_gv49otf',
          form.current,
          'x56yMyCHRWxKS2Ed7'
        )
        .then(
          (result) => {
            if (result.text === 'OK') {
              setOpenModal(false);
              setIsLoading(false);
              let url = 'https://docsend.com/view/uc36kkaagwdqdivd';
              window.open(url, '_blank');
            }
          },
          (error) => {
            setIsLoading(false);
            toast.error(error.text || 'Oops, Something went wrong');
          }
        );
    } else {
      // handle invalid email
      setIsError('Kindly enter a valid email.');
      setInterval(() => {
        setIsError('');
      }, 2000);
    }
  };

  return (
    <BackDrop isOpen={true} onClick={handleClose}>
      <motion.div
        className="z-10 w-10/12 bg-[#13151a] pt-4 pr-4 rounded-lg  lg:w-7/12 relative "
        onClick={(e) => {
          e.stopPropagation();
        }}
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        exit={{
          scale: 0.5,
          opacity: 0,
          y: 200,
          transition: { duration: 0.2 },
        }}
      >
        <div className="flex justify-end">
          {' '}
          <img
            src="images/close.svg"
            alt="closeIcon"
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>
        <div className="lg:flex gap-10">
          <div className="w-1/2">
            <div className="z-20 lg:block hidden absolute top-0 left-0">
              <img src="images/lines.svg" alt="lines" />
            </div>
            <h3 className="font-mena lg:text-[30px] text-[24px] text-[#E2EEFF] w-[260px] lg:mt-6 lg:ml-12 ml-6 lg:mb-4 mb-2">
              Download a FREE copy of Africa's Financial Report - 2022
            </h3>

            <div className="lg:block hidden">
              <img src="images/Mockup.svg" alt="Mockup" />
            </div>
          </div>
          <div className="lg:w-1/2 lg:mt-[4.5rem] font-mena ">
            <div className="lg:w-[90%]  pl-6 lg:pl-0">
              <p className="text-[#E2EEFF] text-[14px] lg:text-[16px]">
                Receive updates on the global financial market and how it
                affects you.
              </p>
              <form ref={form}>
                <input
                  className="bg-[#191C22] py-3 px-5 rounded-[40px] mt-8 lg:mt-10 w-full placeholder:text-[#68749C] outline-none text-[#68749c]"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                  defaultValue={email}
                  id="email"
                  name="email"
                  type="email"
                />
              </form>
              <small className="text-[#ff0000]">{isError}</small>
              <div className="grid lg:mt-6 mt-4 lg:mb-8 mb-4 gap-2">
                <button
                  className="bg-[#0747A6] text-white rounded-[40px] py-3 btn-style disabled:opacity-80"
                  disabled={!email}
                  onClick={handleSignUp}
                >
                  {isLoading ? (
                    <i
                      className="fa fa-spinner fa-pulse mx-2 "
                      aria-hidden="true"
                    ></i>
                  ) : (
                    'Download'
                  )}
                </button>
              </div>

              <p className="lg:text-left text-center text-[12.5px] text-[#68749C]">
                By providing your email address you agree to our{' '}
                <a
                  href="/terms-and-conditions"
                  target={'_blank'}
                  className="text-[#4C9AFF] underline"
                >
                  Terms
                </a>{' '}
                and{' '}
                <a
                  href="/privacy-policy"
                  target={'_blank'}
                  className="text-[#4C9AFF] underline"
                >
                  Privacy Policy.
                </a>
              </p>
            </div>
            <div className="lg:hidden block">
              <img src="images/Mockupsm.svg" alt="Mockup" />
            </div>
          </div>
        </div>
      </motion.div>
    </BackDrop>
  );
};

export default FinancialReportModal;
