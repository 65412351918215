import React, { useEffect, useState } from "react";
import FiatInput from "../Input/FiatInput";
import tw from "tailwind-styled-components";
import CryptoInput from "../Input/CryptoInput";
import Rate from "../Input/Rate";
import ErrorMessage from "../Input/ErrorMessage";
import { useOrder } from "hooks/index";

const SellOrderForm = ({ fiatCurrencies, cryptoCurrencies }) => {
  const [minAmountErrorMessage, setMinAmountErrorMessage] = useState(false);
  const [maxAmountErrorMessage, setMaxAmountErrorMessage] = useState(false);
  const [minAmount, setMinAmount] = useState(1);
  const [maxAmount, setMaxAmount] = useState();
  const [rateError, setRateError] = useState();
  const [bestRate, setBestRate] = useState();
  const [startingRate, setStartingRate] = useState();
  const [fiatCurrency, setFiatCurrency] = useState();
  const [cryptoCurrency, setCryptoCurrency] = useState();
  const [cryptoNetwork, setCryptoNetwork] = useState();
  const [fiatNetwork, setFiatNetwork] = useState();
  const [fiatValue, setFiatValue] = useState("");
  const [cryptoValue, setCryptoValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { getBestRate, handleOrderRate } = useOrder();

  useEffect(() => {
    getBestRate(
      cryptoCurrency || cryptoCurrencies?.[0]?.currencySymbol,
      fiatCurrency || fiatCurrencies?.[0]?.currencySymbol,
      cryptoNetwork || cryptoCurrencies?.[0]?.network,
      fiatNetwork || fiatCurrencies?.[0]?.network,
      "sell",
      null,
      null,
      null,
      null,
      setBestRate,
      setStartingRate,
      setRateError
    );
    const intervalCall = setInterval(() => {
      getBestRate(
        cryptoCurrency || cryptoCurrencies?.[0]?.currencySymbol,
        fiatCurrency || fiatCurrencies?.[0]?.currencySymbol,
        cryptoNetwork || cryptoCurrencies?.[0]?.network,
        fiatNetwork || fiatCurrencies?.[0]?.network,
        "sell",
        null,
        null,
        null,
        null,
        setBestRate,
        setStartingRate,
        setRateError
      );
    }, 20000); //20 seconds
    return () => {
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cryptoCurrency,
    cryptoNetwork,
    fiatCurrency,
    fiatNetwork,
    fiatCurrencies,
    cryptoCurrencies,
  ]);

  const submitHandler = async () => {
    setIsLoading(true);
    window.location.href = "https://lite.xendbridge.com/";
  };

  return (
    <div>
      <Label>You Sell</Label>
      <FiatInput
        fiatCurrency={fiatCurrency || fiatCurrencies?.[0]?.currencySymbol}
        setFiatCurrency={setFiatCurrency}
        setFiatNetwork={setFiatNetwork}
        fiatCurrencies={fiatCurrencies}
        handleOrderRate={handleOrderRate}
        setMinAmountErrorMessage={setMinAmountErrorMessage}
        setMaxAmountErrorMessage={setMaxAmountErrorMessage}
        cryptoValue={cryptoValue}
        fiatValue={fiatValue}
        setFiatValue={setFiatValue}
        setMaxAmount={setMaxAmount}
        maxAmount={maxAmount}
        setMinAmount={setMinAmount}
        fiatNetwork={fiatNetwork || fiatCurrencies?.[0]?.network}
        cryptoCurrency={cryptoCurrency || cryptoCurrencies?.[0]?.currencySymbol}
        cryptoNetwork={cryptoCurrencies?.[0]?.network}
        setCryptoValue={setCryptoValue}
      />
      <Label>You Receive</Label>
      <CryptoInput
        cryptoCurrency={cryptoCurrency || cryptoCurrencies?.[0]?.currencySymbol}
        setCryptoCurrency={setCryptoCurrency}
        setCryptoNetwork={setCryptoNetwork}
        fiatCurrencies={fiatCurrencies}
        cryptoCurrencies={cryptoCurrencies}
        handleOrderRate={handleOrderRate}
        setMinAmountErrorMessage={setMinAmountErrorMessage}
        setMaxAmountErrorMessage={setMaxAmountErrorMessage}
        fiatValue={fiatValue}
        cryptoValue={cryptoValue}
        setCryptoValue={setCryptoValue}
        maxAmount={maxAmount}
        cryptoNetwork={cryptoNetwork || cryptoCurrencies?.[0]?.network}
        fiatCurrency={fiatCurrency || fiatCurrencies?.[0]?.currencySymbol}
        fiatNetwork={fiatNetwork || fiatCurrencies?.[0]?.network}
        setMinAmount={setMinAmount}
        setFiatValue={setFiatValue}
        setMaxAmount={setMaxAmount}
      />
      <ErrorMessage
        minAmountErrorMessage={minAmountErrorMessage}
        maxAmountErrorMessage={maxAmountErrorMessage}
        maxAmount={maxAmount}
        rateError={rateError}
      />
      <Rate
        startingRate={startingRate}
        fiatCurrency={fiatCurrency || fiatCurrencies?.[0]?.currencySymbol}
        cryptoCurrency={cryptoCurrency || cryptoCurrencies?.[0]?.currencySymbol}
        bestRate={bestRate}
      />
      <div className="grid mt-10 gap-2">
        <button
          className="bg-[#0747A6] text-white rounded-[40px] py-3 btn-style disabled:opacity-80"
          disabled={
            !fiatValue ||
            !cryptoValue ||
            cryptoValue < minAmount ||
            cryptoValue > maxAmount
          }
          onClick={submitHandler}
        >
          {isLoading ? (
            <i className="fa fa-spinner fa-pulse mx-2 " aria-hidden="true"></i>
          ) : (
            "Sell"
          )}
        </button>
      </div>
    </div>
  );
};

const Label = tw.label`text-[#7C7C7C] lg:text-lg text-base`;

export default SellOrderForm;
