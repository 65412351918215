import React from "react";
import { navLinks } from "utils/fx";
import { useLocation } from "react-router-dom";

const DesktopHeader = ({ is404 }) => {
  let location = useLocation();
  const isBlueBg =
    location.pathname === "/business" ||
    location.pathname === "/privacy-policy";

  return (
    <nav
      className={`${
        isBlueBg ? "bg-[#0747A6]" : "bg-white"
      }  2xl:px-[18rem] lg:px-[13.3rem] w-full mx-auto py-7 fixed lg:flex hidden justify-between font-mena font-normal items-center fixed z-20`}
    >
      {isBlueBg ? (
        <a href="/">
          <img src="images/logosm.svg" alt="logo" />
        </a>
      ) : (
        <a href="/">
          <img src="images/logo.svg" alt="logo" />
        </a>
      )}
      {!is404 && (
        <>
          {" "}
          <div>
            {navLinks.map(({ title, path, target }, index) => (
              <a
                href={path}
                className={`px-4 hover:text-[#4C9AFF]  text-sm ${
                  `${location.pathname}${location.hash}` === path
                    ? "text-[#4C9AFF]"
                    : `${isBlueBg ? "text-white" : "text-black"}`
                }`}
                key={index}
                target={target}
              >
                {title}
              </a>
            ))}
          </div>
          <div className=" flex items-center text-sm ">
            <a
              href="https://integrationssandbox.xendbridge.com/thirdparty/"
              className={`${
                isBlueBg ? "text-white" : "text-black"
              } font-bold mr-7`}
              target={"_blank"}
              rel="noreferrer"
            >
              Sign in
            </a>
            <a
              href="https://integrationssandbox.xendbridge.com/thirdparty/register"
              className="mr-7 bg-[#4C9AFF] text-sm text-white h-[35px] w-[121px] flex items-center justify-center rounded-[42px] hover:opacity-90"
              target={"_blank"}
              rel="noreferrer"
            >
              Get started
            </a>
            {/* <span
          className={`${isBusiness ? "text-white" : "text-black"} font-bold`}
        >
          En
        </span> */}
          </div>
        </>
      )}
    </nav>
  );
};

export default DesktopHeader;
