import React from "react";
import Fade from 'react-reveal/Fade';

const SectionThree = () => {
  return (
    <Fade bottom cascade>
      <div className="container lg:grid grid-cols-2 gap-10 mx-auto  text-white lg:px-[8.3rem] px-5 mb-10 font-mena  ">
        <div className="w-full  lg:bg-[#0747A6] bg-from-blue-to-white rounded-lg  lg:mb-0 mb-5  lg:p-10 p-10 pb-[90px]">
          <h4 className="lg:text-4xl text-3xl lg:w-3/4 mb-14">
            Power your busines with our P2P payment solutions.
          </h4>
          <a
            href="https://integrationssandbox.xendbridge.com/thirdparty/register"
            className="mr-7 px-6 bg-[#fff] text-[#0747A6] py-3 rounded-[42px]  hover:opacity-90"
            target={"_blank"}
            rel="noreferrer"
          >
            Integrate API
          </a>
        </div>
        <div className="w-full  lg:bg-[#0747A6] bg-from-blue-to-white  rounded-lg  lg:p-10 p-10 pb-[90px]">
          <h4 className="lg:text-[2.4rem] leading-tight text-3xl mb-20">
          Trade crypto on the go; fast, simple, and secure!
          </h4>
          <a
            href="https://integrationssandbox.xendbridge.com/thirdparty/register"
            className="mr-7 px-6 bg-[#fff] text-[#0747A6] py-3 rounded-[42px]  hover:opacity-90"
            target={"_blank"}
            rel="noreferrer"
          >
            Start Trading
          </a>
        </div>
      </div>
    </Fade>
  );
};

export default SectionThree;
