import { api } from "./index";

export const orderEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    getOrderRateBounds: build.mutation({
      query: (payload) => ({
        url: `/Order/RateBounds?payInCurrencyCode=${payload?.payInCurrencyCode}&payInCurrencyNetwork=${payload?.payInCurrencyNetwork}&receiveInCurrencyCode=${payload?.receiveInCurrencyCode}&receiveInCurrencyNetwork=${payload?.receiveInCurrencyNetwork}&orderAmount=${payload?.orderAmount}`,
        method: "GET",
      }),
    }),
    getOrderRate: build.mutation({
      query: (payload) => ({
        url: `/Order/Rate?payInCurrencyCode=${payload?.payInCurrencyCode}&payInCurrencyNetwork=${payload?.payInCurrencyNetwork}&receiveInCurrencyCode=${payload?.receiveInCurrencyCode}&receiveInCurrencyNetwork=${payload?.receiveInCurrencyNetwork}&orderAmount=${payload?.orderAmount}`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetOrderRateBoundsMutation, useGetOrderRateMutation } =
  orderEndpoint;
