import React from "react";
import Header from "components/Header";
import SectionOne from "components/Home/SectionOne";
import Trading from "components/Home/Trading";
import TrustedPartners from "components/Home/TrustedPartners";
import About from "components/Home/About";
import SectionTwo from "components/Home/SectionTwo";
import Testimonial from "components/Home/Testimonial";
import News from "components/Home/News";
import JoinOurCommunity from "components/JoinOurCommunity";
import SectionThree from "components/Home/SectionThree";
import Footer from "components/Footer";

const Index = () => {
  return (
    <>
      <Header />
      <SectionOne />
      <Trading />
      <TrustedPartners />
      <About />
      <SectionTwo />
      <Testimonial />
      <News />
      <JoinOurCommunity />
      <SectionThree />
      <Footer />
    </>
  );
};

export default Index;
